.map-filter-roads-types {
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 380px;
    top: 90px;
    left: 0px;
    padding: 10px;
}
     
.map-filter-roads-types-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
    width: 357px;
    height: 240px;
}

.map-filter-roads-types-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 6px;
    color: #5B5B5B;
}

.map-filter-roads-types-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.column-1-eq {
    float: left;
    width: 50%;
    padding: 0px;
  }

  .column-2-eq {
    float: right;
    width: 50%;
    padding-top: 0px;
  }

  /*DashBoard Hotspot Potential*/
.dashboard-potential {
	background: #17a79d48;
	background-size: 400% 400%;
}

.dashboard-potential-inner{
    height: 100vh;
}

.dashboard-potential-inner .dashboard-interactions{
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 12%;
    left: 0;
    padding-left: 17vw;
}

.dashboard-interactions-inner{
    background-color: #028080ea;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    width: 1100px;
    height: 650px;
}

.dashboard-interactions-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 18px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #232323;
  }
  
  .dashboard-interactions-inner h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 18px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #fafafa;
  }

  .dashboard-interactions-inner .select_dashboard{
    padding-left: 30%;
    padding-right: 30%;
  }

  .dashboard-potential-inner .dashboard-companies{
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 270px;
    left: 0;
    padding-left: 17vw;
}

.dashboard-companies-inner{
    background-color: #028080d3;
    border-radius: 0px 0px 10px 10px;
    padding: 20px;
    margin-bottom: 20px;
    width: 1100px;
    height: 500px;
}

.dashboard-companies-inner .row {
    justify-content: center;
    padding-left: 7%;
    padding-right: 7%;
    height: 160px;
}

.dashboard-companies-inner .column-dash-331 {
    float: left;
    width: 20%;
    padding: 0%;
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 0px;
   background-color: #023d8a85;
}

.dashboard-companies-inner .column-dash-332 {
    float: left;
    width: 20%;
    padding: 0%;
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 0px;
   background-color: #0077b685;
}

.dashboard-companies-inner .column-dash-333 {
    float: left;
    width: 20%;
    padding: 0%;
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 0px;
   background-color: #0096c785;
}

.dashboard-companies-inner .column-dash-2 {
    float: left;
    width: 17%;
    padding: 0%;
    padding-left: 5%;
    margin-top: 0px;
}


.dashboard-companies-inner .column-dash-5 {
    float: left;
    width: 5%;
    padding: 0%;
    padding-left: 1%;
    margin-top: 0px;
}

.modal-move-between-elements{
    padding: 0px;
}

.modal-move-between-elements-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-top: 10px;
    margin-bottom: 15px;
    width: 42px;
    height: 35px;
    justify-content: center;
}

.dashboard-companies-inner .graph-img{
    height: 80px;
    padding-right: 10px;
}

.price {
    list-style-type: none;
    border: 1px solid #dfffff;
    margin: 0;
    padding: 0;
  }
  
.price li {
    border-bottom: 1px solid #dfffff;
    padding: 10px;
    text-align: center;
  }
  

  ul .two{
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    list-style-type: none;
    padding-left: 0px;
  }

  .price li .two-elements{
    border-bottom: 0px solid #eee;
    padding-top: 15px;
    padding-left: 0px;
    padding-right: 0px;
    text-align: center;

    color: #f5f5f5;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
  }

  .header{
    height: 40px;
    display: flex;
    justify-content: center;
        align-items: center;
  }

  .list-empty-padding{
    height: 215px;
  }

.dashboard-item-title {
    color: #ffffff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    text-decoration: none !important;
  }

  .graph-label-data-company{
    color: #ffffff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    text-decoration: none !important;
  }

  .graph-label-data-company-potential{
    color: #1c1c1c;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    text-decoration: none !important;
  }
  

  .menu_dashboard_titles {
    list-style-type: none;
    border: 0px solid #dfffff;
    margin: 0;
    padding: 0;
  }
  
.menu_dashboard_titles li {
    border-bottom: 0px solid #dfffff;
    padding: 10px;
    text-align: center;
  }


  .menu_dashboard_titles li .two-elements{
    border-bottom: 0px solid #eee;
    padding: 20px;
    text-align: center;
  }

  /*OD Matrix*/
  .dashboard-od-matrix {
    background: #8fcbc748;
    background-size: 400% 400%;
  }
  
  .dashboard-od-matrix-inner{
      height: 100vh;
  }
  
  .modal-od-matrix {
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 90px;
    left: 0px;
    padding: 10px;
  }
  
  .modal-od-matrix-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 640px;
    height: 80px;
  }

  .modal-od-matrix-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
  }

  .modal-od-matrix-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none !important;
}

/*Test - OD Matrix*/
.xLabels {
  position: sticky;
  bottom: 0;
}

/* Make the y labels stick to the right */
.yLabels {
  position: sticky;
  right: 0;
}