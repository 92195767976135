.map-graph-ovq-vs-traffic{
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 550px;
    top: 90px;
    right: 0;
    padding: 10px;
}

.map-graph-ovq-vs-traffic-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 527px;
    height: 520px;
}

.map-graph-ovq-vs-traffic-inner .visualization-tile{
 margin-bottom: 30px;
}

.map-graph-ovq-vs-traffic-inner .row{
    justify-content: center;
    margin-left: 5px;
    margin-right: -15px;
 }

.map-graph-ovq-vs-traffic-inner .column-3-1{
    float: left;
    width: 25%;
    padding: 0px;
    margin-top: 3px;
}

.map-graph-ovq-vs-traffic-inner .column-3-2{
    float: left;
    width: 33%;
    padding: 0px;
    margin-top: 3px;
}

.map-graph-ovq-vs-traffic-inner .column-3-3{
    float: left;
    width: 35%;
    padding: 0px;
    margin-top: 3px;
}

.map-graph-ovq-vs-traffic-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
    margin-bottom: 0px;
}

.visualization-tile h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
    margin-bottom: 0px;
}

.map-graph-ovq-vs-traffic-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 13px;
}

.map-info{
    position: absolute;
    bottom: 0;
    right: 0px;
    padding: 10px;

}

.map-info-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 10px;
    margin-bottom: 15px;
    width: 42px;
    height: 35px;
    justify-content: center;
}

.map-info-detail{
    position: absolute;
    bottom: 60px;
    right: 0px;
    padding: 10px;

}

.map-info-detail-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 10px;
    margin-bottom: 15px;
    width: 300px;
    height: 50px;
    justify-content: center;
}

.map-filter-for-graph-axis{
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 390px;
    top: 90px;
    left: 0;
    padding: 10px;
}

.map-filter-for-graph-axis-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 378px;
    height: 550px;
}

.map-filter-for-graph-axis-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
}

.map-filter-for-graph-axis-inner .nav-tabs{
    margin-top: 0px;
}

.map-filter-for-graph-axis-inner .insight-analyzer-modal-slider-inner{
    margin-left: 20px;
}

.map-filter-for-graph-axis-inner .label-selected-value{
    font-size: 10px;
    text-align: center;
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
    color: #5B5B5B;
}

.map-filter-for-graph-axis-inner .row{
   justify-content: center;
   margin-left: -10px;
}

.map-filter-for-graph-axis-inner .column-3{
    float: left;
    width: 32%;
    padding: 0px;
}

.map-filter-for-graph-axis-inner .column-2{
    float: left;
    width: 50%;
    padding: 0px;
}

.map-filter-for-graph-axis-inner .column-2-1{
    float: left;
    width: 40%;
    padding: 0px;
    margin-top: 6px;
}

.map-filter-for-graph-axis-inner .column-2-2{
    float: left;
    width: 60%;
    padding: 0px;
    margin-top: 6px;
}

.map-areas2focus-pkm-value{
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 330px;
    top: 640px;
    right: 0px;
    padding: 10px;
  }
  
  .map-areas2focus-pkm-value-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 307px;
    height: 145px;
  }
  
  .map-areas2focus-pkm-value-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 2px;
    color: #9c1f6c;
  }
  
  .map-areas2focus-pkm-value-inner h3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 2px;
    color: #232222;
  }
  
  .map-areas2focus-pkm-value-inner h4{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 2px;
    color: #5B5B5B;
  }

  /*Drop a point - Hotspots*/
  .drop-point-hotspot-params{
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 250px;
    right: 0;
    padding: 10px;
}

.drop-point-hotspot-params-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 350px;
    height: 50px;
}

.drop-point-hotspot-params-inner.visualization-tile{
 margin-bottom: 30px;
}

.drop-point-hotspot-params-inner .row{
    justify-content: center;
    margin-left: 5px;
    margin-right: -15px;
 }

.drop-point-hotspot-params-inner .column-3-1{
    float: left;
    width: 25%;
    padding: 0px;
    margin-top: 3px;
}

.drop-point-hotspot-params-inner .column-3-2{
    float: left;
    width: 33%;
    padding: 0px;
    margin-top: 3px;
}

.drop-point-hotspot-params-inner .column-3-3{
    float: left;
    width: 35%;
    padding: 0px;
    margin-top: 3px;
}

.drop-point-hotspot-params-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
    margin-bottom: 0px;
}

.drop-point-hotspot-params-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 13px;
}

/*Winners and Losers View*/

.modal-winners-losers-filters{
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 90px;
    right: 0px;
    padding: 10px;
  }
  
.modal-winners-losers-filters-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 300px;
    overflow: auto;
  }

  .modal-winners-losers-filters-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
  }

  .modal-winners-losers-filters-inner .form-check-label {
    color: #2b2b2b;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
}

  .modal-winners-losers-filters-inner  h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 2px;
    color: #5B5B5B;
  }
  
  .modal-winners-losers-filters-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    margin-top: 2px;
    margin-bottom: 5px;
  }
  
  .modal-winners-losers-filters-inner .slider-distance{
    margin-left: 30px;
  }

  .map-success-assigned-category{
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 490px;
    padding: 10px;
}

.map-success-assigned-category-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 350px;
    height: 30px;
}

.map-success-assigned-category-inner h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 2px;
    color: #5B5B5B;
  }