/*Layer Button*/

.map-layer-button{
    position: absolute;
    bottom: 0;
    left: 50px;
    padding: 10px;

}

.map-layer-options .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none !important;
}

.map-layer-button-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 10px;
    margin-bottom: 15px;
    width: 40px;
    height: 35px;
    justify-content: center;
}

.map-layer-button-inner .btn-icon-layer{
    padding-top: 4px;
}

.map-layer-option-car .btn-icon-layer{
    padding-top: 4px;
}

.map-layer-option-train .btn-icon-layer{
    padding-top: 4px;
}

.map-layer-options{
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    width: 10px;
}

.map-layer-option-car{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 7px;
    margin-bottom: 15px;
    width: 100px;
    height: 35px;
    justify-content: center;
}

.map-layer-option-train{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 7px;
    margin-bottom: 80px;
    width: 100px;
    height: 35px;
    justify-content: center;
}

.map-layer-option-car{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 7px;
    margin-bottom: 15px;
    width: 100px;
    height: 40px;
    justify-content: center;
}

.map-layer-option-train{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 7px;
    margin-bottom: 80px;
    width: 100px;
    height: 40px;
    justify-content: center;
}

.map-layer-option-access{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 7px;
    margin-bottom: 15px;
    width: 100px;
    height: 40px;
    justify-content: center;
}

.map-layer-option-recommendation{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 7px;
    margin-bottom: 15px;
    width: 100px;
    height: 40px;
    justify-content: center;
}

.map-layer-option-util-rail{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 7px;
    margin-bottom: 15px;
    width: 100px;
    height: 40px;
    justify-content: center;
}

.map-layer-option-util-road{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 7px;
    margin-bottom: 15px;
    width: 100px;
    height: 40px;
    justify-content: center;
}

.map-layer-option-complete{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 7px;
    margin-bottom: 15px;
    width: 100px;
    height: 40px;
    justify-content: center;
}

.map-layer-options2{
    position: absolute;
    bottom: 65px;
    left: 110px;
    padding: 10px;
    width: 10px;
}

.map-layer-options2 .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none !important;
}