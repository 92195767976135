.map-what-if-potential-filters {
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 280px;
    bottom: 0px;
    right: 0;
    padding: 10px;
}
     
.map-what-if-potential-filters-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
    width: 257px;
    height: 450px;
}

.map-what-if-potential-filters-inner .label-selected-value{
    font-size: 10px;
    text-align: center;
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
}

.map-what-if-potential-filters-inner .insight-analyzer-modal-slider-inner{
    margin-left: 20px;
}

.map-what-if-potential-filters-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 6px;
    color: #5B5B5B;
}

.map-what-if-potential-filters-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.map-what-if-potential-filters-inner .row{
    margin-top: 10px;
}

.slider-centering{
    margin-left: 20px;
}

.slider-centering .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none !important;
}

.map-what-if-route-breakdown {
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 450px;
    bottom: 480px;
    right: 0;
    padding: 10px;
}
     
.map-what-if-route-breakdown-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
    width: 427px;
    height: 240px;
}

.map-what-if-route-breakdown-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 6px;
    color: #5B5B5B;
}

.map-what-if-route-breakdown-inner-scroll {
    width: 427px;
    height: 190px;
    overflow-y: scroll;
}

.table-tittle{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}

.table-data{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

/*What if -> Potential Bike*/
.map-what-if-potential-filters-bike {
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 280px;
    bottom: 0px;
    right: 0;
    padding: 10px;
}
     
.map-what-if-potential-filters-bike-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
    width: 257px;
    height: 410px;
}

.map-what-if-potential-filters-bike-inner .label-selected-value{
    font-size: 10px;
    text-align: center;
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
}

.map-what-if-potential-filters-bike-inner .insight-analyzer-modal-slider-inner{
    margin-left: 20px;
}

.map-what-if-potential-filters-bike-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 6px;
    color: #5B5B5B;
}

.map-what-if-potential-filters-bike-inner .label-des{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.map-what-if-potential-filters-bike-inner .row{
    margin-top: 10px;
}

.map-what-if-route-breakdown-bike {
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 430px;
    bottom: 440px;
    right: 0;
    padding: 10px;
}
     
.map-what-if-route-breakdown-bike-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
    width: 407px;
    height: 250px;
}

.map-what-if-route-breakdown-bike-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 6px;
    color: #5B5B5B;
}

.map-what-if-route-breakdown-bike-inner-scroll {
    width: 407px;
    height: 210px;
    overflow-y: scroll;
}