@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

/*Mobility Insights*/
.map-card-insight-analyzer {
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 280px;
    bottom: 0px;
    right: 0;
    padding: 10px;
}
     
.map-card-insight-analyzer-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 257px;
    height: 300px;
}

.map-card-insight-analyzer-inner .label-selected-value{
    font-size: 10px;
    text-align: center;
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
}

.map-card-insight-analyzer-inner .insight-analyzer-slider-inner{
    margin-left: 20px;
}

.map-card-insight-analyzer-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
}

.map-card-insight-analyzer-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

/*Slider Change color*/
  .rc-slider{
    width: 90%;
    }
  .rc-slider-track {
    background-color: rgb(15, 171, 171);
  }
  .rc-slider-handle {
    border: solid 2px rgb(15, 171, 171);
  }
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: rgb(15, 171, 171);
    box-shadow: 0 0 0 5px rgb(15, 171, 171);
  }
  .rc-slider-handle:focus-visible {
    border-color: rgb(15, 171, 171);
    box-shadow: 0 0 0 3px rgb(15, 171, 171);
  }
  .rc-slider-handle-click-focused:focus {
    border-color: rgb(15, 171, 171);
    box-shadow: unset;
  }
  .rc-slider-handle:hover {
    border-color: rgb(15, 171, 171);
  }
  .rc-slider-handle:active {
    border-color: rgb(15, 171, 171);
    box-shadow: 0 0 5px rgb(15, 171, 171);
  }
  .rc-slider-dot-active {
    border-color: rgb(15, 171, 171);
  }

.form-check-label{
    color: #000000;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
}

/*Information Button*/
.map-info-button{
  position: absolute;
  top: 80px;
  right: 0;
  padding: 10px;
}

.map-info-button-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 2px;
  padding-top: 10px;
  margin-bottom: 15px;
  width: 40px;
  height: 35px;
  justify-content: center;
}

.info-description{
  position: absolute;
    top: 140px;
    right: 0;
    padding: 10px;
}

.map-info-description-box{
  background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 7px;
    margin-bottom: 15px;
    width: 400px;
    height: 45px;
    justify-content: center;
    font-family: 'Inter';
    font-style: normal;
    font-size: 11px;
}

/*3D Button*/
.map-3d-button{
  position: absolute;
  bottom: 330px;
  padding: 15px;
  right: 0;
}

.map-3d-button-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 2px;
  padding-top: 10px;
  margin-bottom: 15px;
  width: 40px;
  height: 35px;
  justify-content: center;
}

.map-graph-space{
  position: absolute;
  top: 80px;
  padding: 15px;
  left: 0;
}


.map-graph-space-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 2px;
  width: 450px;
  height: 280px;
  justify-content: center;
}

.map-graph-space-inner h3{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 5px;
  color: #5B5B5B;
}

.map-graph-space-inner .no-data{
  font-family: 'Inter';
  font-style: normal;
  font-size: small;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  margin-top: 10px;
  color: #6f6f6f;
}

.map-graph-space-inner h4{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: small;
  color: #5B5B5B;
}

/*Point to Point Insights Modal*/
.map-p-t-p-insights{
  position: absolute;
  top: 80px;
  padding: 15px;
  left: 0;
}


.map-p-t-p-insights-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 2px;
  width: 500px;
  height: 413px;
  justify-content: center;
}

.map-p-t-p-insights-inner h3{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 5px;
  color: #5B5B5B;
}

.map-p-t-p-insights-inner .no-data{
  font-family: 'Inter';
  font-style: normal;
  font-size: small;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  margin-top: 10px;
  color: #6f6f6f;
}

.map-p-t-p-insights-inner h4{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: small;
  color: #5B5B5B;
}

.map-p-t-p-insights-inner .row h4{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: small;
  color: #5B5B5B;
}

.p-t-p-table-insigths{
  margin-top: 10px;
}

.p-t-p-table-insigths .column-1 {
  float: left;
  width: 60%;
  padding: 0px;
}

.p-t-p-table-insigths .column-2 {
  float: left;
  width: 40%;
  padding: 0px;
}

.p-t-p-table-insigths .row {
  margin-top: 20px;
  margin-bottom: 20px;
}