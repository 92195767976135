

.modal-employers-analyzer {
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 90px;
    right: 0px;
    padding: 10px;
  }
  
  .modal-employers-analyzer-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 410px;
    height: 380px;
  }
  
  .modal-employers-analyzer-inner .nav-tabs {
    margin-top: 8px;
  }
  
  .modal-employers-analyzer-inner h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
  }
  
  .modal-employers-analyzer-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .modal-employers-analyzer-inner .nav-link{
    padding-right: 8px;
    padding-left: 8px;
}
  
  .modal-employers-analyzer-inner .label-2{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    margin-top: 2px;
    margin-bottom: 0px;
  }

  .modal-employers-analyzer-inner .label-3{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .modal-selected-headquaters {
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 500px;
    right: 0px;
    padding: 10px;
}

.modal-selected-headquaters-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 410px;
    height: 250px;
}

  .column-2-30{
    float: right;
    width: 25%;
    padding: 0px;
  }
  
  .column-3-15{
    float: right;
    width: 20%;
    padding: 0px;
  }

  .modal-no-headquaters {
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 500px;
    right: 0px;
    padding: 10px;
}

.modal-no-headquaters-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 410px;
    height: 50px;
}

.modal-no-headquaters-inner h2{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  margin-top: 6px;
  color: #5B5B5B;
}

  .modal-no-headquaters .no-data{
    font-family: 'Inter';
    font-style: normal;
    font-size: small;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    color: #6f6f6f;
  }
  
  .fixed-box-headquaters{
    overflow-y: scroll;
    width: 380px;
    height: 240px;
}


/* The actual timeline (the vertical ruler) */
.timeline-headquaters {
    position: relative;
    width: 360px;
    height: auto;
    margin: 0 auto;
  }


  .headquarters-employer{
    color: #323232;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 0px;
    text-decoration: none !important;
  }

  .headquarters-address{
    color: #323232;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none !important;
  }

  .headquaters-visualization-percentage-modal{
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 90px;
    left: 0;
    padding: 10px;
  }
  
  .headquaters-visualization-percentage-modal-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 450px;
    height: 40px;
  }
  
  .headquaters-visualization-percentage-modal-inner h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 5px;
    color: #5B5B5B;
  }

  .headquaters-visualization-type-modal{
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 160px;
    left: 0;
    padding: 10px;
  }
  
  .headquaters-visualization-type-modal-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 450px;
    height: 40px;
  }
  
  .headquaters-visualization-type-modal-inner h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 5px;
    color: #5B5B5B;
  }

  .headquaters-btn-tile-potential-resp-1{
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 140px;
    top: 230px;
    left: 0px;
    padding: 10px;
  }
  
  .headquaters-btn-tile-potential-resp-2{
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 140px;
    top: 230px;
    left: 160px;
    padding: 10px;
  }
  
  .headquaters-btn-tile-potential-resp-3{
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 140px;
    top: 230px;
    left: 320px;
    padding: 10px;
  }
  
  .headquaters-btn-tile-potential-resp-1-inner{
    background-color: #b6d6a7;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 130px;
    height: 80px;
  }
  
  .headquaters-btn-tile-potential-resp-2-inner{
    background-color: #fae391;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 130px;
    height: 80px;
  }
  
  .headquaters-btn-tile-potential-resp-3-inner{
    background-color: #e89998;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 130px;
    height: 80px;
  }
  
  .headquaters-btn-tile-potential-resp-1-inner h3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 18px;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 10px;
    color: #2b2b2b;
  }
  
  .headquaters-btn-tile-potential-resp-1-inner h4{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 2px;
    color: #494949;
  }
  
  .headquaters-btn-tile-potential-resp-1-inner h5{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 2px;
    color: #494949;
  }
  
  .headquaters-btn-tile-potential-resp-2-inner h3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 18px;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 10px;
    color: #2b2b2b;
  }
  
  .headquaters-btn-tile-potential-resp-2-inner h4{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 2px;
    color: #494949;
  }
  
  .headquaters-btn-tile-potential-resp-2-inner h5{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 2px;
    color: #494949;
  }
  
  .headquaters-btn-tile-potential-resp-3-inner h3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 18px;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 10px;
    color: #2b2b2b;
  }
  
  .headquaters-btn-tile-potential-resp-3-inner h4{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 2px;
    color: #494949;
  }
  
  .headquaters-btn-tile-potential-resp-3-inner h5{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 2px;
    color: #494949;
  }
  
  .column-headquarter-1{
    float: left;
    width: 19%;
    padding: 0px;
  }

  .column-headquarter-2{
    float: left;
    width: 23%;
    padding: 0px;
  }

  .column-headquarter-3{
    float: left;
    width: 20%;
    padding: 0px;
  }

  .column-headquarter-4{
    float: left;
    width: 18%;
    padding: 0px;
  }

  .column-headquarter-5{
    float: left;
    width: 21%;
    padding: 0px;
  }

  .column-headquarters-1{
    float: left;
    width: 15%;
    padding: 0px;
  }

  .column-headquarters-2{
    float: left;
    width: 21%;
    padding: 0px;
  }

  .column-headquarters-3{
    float: left;
    width: 37%;
    padding: 0px;
  }

  .column-headquarters-4{
    float: left;
    width: 25%;
    padding: 0px;
  }

  .column-headquarters-5{
    float: left;
    width: 21%;
    padding: 0px;
  }

  .row-headquarter-3dview{
    padding-top: 5px;
  }

  .headquaters-visualization-type-modal-3D{
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 160px;
    left: 0;
    padding: 10px;
  }
  
  .headquaters-visualization-type-modal-3D-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 450px;
    height: 40px;
  }
  
  .headquaters-visualization-type-modal-3D-inner h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 5px;
    color: #5B5B5B;
  }

  .headquaters-visualization-color-scheme-modal-3D{
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 340px;
    left: 0;
    padding: 10px;
  }
  
  .headquaters-visualization-color-scheme-modal-3D-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 450px;
    height: 100px;
  }
  
  .headquaters-visualization-color-scheme-modal-3D-inner h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 5px;
    color: #5B5B5B;
  }

  .headquaters-visualization-color-scheme-modal-3D-inner .label-2{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-top: 2px;
    margin-bottom: 0px;
    margin-left: 30px;
    width: 150px;
    height: 16px;
    text-align: left;
  }

  .square {
    height: 16px;
    width: 16px;
  }

  .headquaters-visualization-color-scheme-modal-3D-inner .column-30{
    float: left;
    width: 33.3%;
    padding: 0px;
  }

  .headquaters-visualization-color-scheme-modal-3D-inner .label-50-2{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-top: 2px;
    margin-bottom: 0px;
    margin-left: 30px;
    width: 200px;
    height: 16px;
    text-align: left;
  }
  
  .headquaters-visualization-color-scheme-modal-3D-inner .column-50{
    float: left;
    width: 50%;
    padding: 0px;
  }



.headquaters-visualization-color-scheme-modal-2-3D{
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 340px;
    left: 0;
    padding: 10px;
  }
  
  .headquaters-visualization-color-scheme-modal-2-3D-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 450px;
    height: 180px;
  }
  
  .headquaters-visualization-color-scheme-modal-2-3D-inner h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 5px;
    color: #5B5B5B;
  }

  .headquaters-visualization-color-scheme-modal-2-3D-inner .label-2{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-top: 2px;
    margin-bottom: 0px;
    margin-left: 30px;
    width: 150px;
    height: 16px;
    text-align: left;
  }

  .headquaters-visualization-color-scheme-modal-2-3D-inner .column-30{
    float: left;
    width: 33.3%;
    padding: 0px;
  }

  .headquaters-visualization-color-scheme-modal-2-3D-inner .label-50-2{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-top: 2px;
    margin-bottom: 0px;
    margin-left: 30px;
    width: 200px;
    height: 16px;
    text-align: left;
  }
  
  .headquaters-visualization-color-scheme-modal-2-3D-inner .column-50{
    float: left;
    width: 50%;
    padding: 0px;
  }

  

  .modal-no-headquaters-b2b-bus {
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 100px;
    right: 0px;
    padding: 10px;
}

.modal-no-headquaters-b2b-bus-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 410px;
    height: 100px;
}

.modal-no-headquaters-b2b-bus-inner h2{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  margin-top: 6px;
  color: #5B5B5B;
}

.modal-no-headquaters-b2b-bus-inner p{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  margin-top: 6px;
  color: #5B5B5B;
}

  .modal-no-headquaters-b2b-bus .no-data{
    font-family: 'Inter';
    font-style: normal;
    font-size: small;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    color: #6f6f6f;
  }

  .b2b-bus-btn-tile-potential-resp-1{
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 140px;
    top: 100px;
    left: 0px;
    padding: 10px;
  }
  
  .b2b-bus-btn-tile-potential-resp-2{
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 140px;
    top: 100px;
    left: 160px;
    padding: 10px;
  }
  
  .b2b-bus-btn-tile-potential-resp-3{
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 140px;
    top: 100px;
    left: 320px;
    padding: 10px;
  }