
.modal-regional-demand-step1 {
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 220px;
    right: 0;
    padding: 10px;
}
     
.modal-regional-demand-step1-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
    width: 300px;
    height: 180px;
}

.modal-regional-demand-step1-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
    margin-bottom: 0px;
}

.modal-regional-demand-step1-inner  .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-decoration: none !important;
}

.modal-regional-demand-step2 {
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 220px;
    right: 0;
    padding: 10px;
}
     
.modal-regional-demand-step2-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
    width: 300px;
    height: 200px;
}

.modal-regional-demand-step2-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
    margin-bottom: 0px;
}

.modal-regional-demand-step2-inner  .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-decoration: none !important;
}

.modal-regional-demand-step2-inner .column-1-50{
    float: left;
    width: 50%;
    padding: 0px;
  }
  
  .modal-regional-demand-step2-inner .column-2-50{
    float: left;
    width: 50%;
    padding: 0px;
  }

  .modal-regional-demand-step3 {
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 220px;
    right: 0;
    padding: 10px;
}
     
.modal-regional-demand-step3-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
    width: 300px;
    height: 210px;
}

.modal-regional-demand-step3-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
    margin-bottom: 0px;
}

.modal-regional-demand-step3-inner  .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-decoration: none !important;
}

.modal-regional-demand-type {
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 90px;
    right: 0;
    padding: 10px;
}
     
.modal-regional-demand-type-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
    width: 300px;
    height: 100px;
}

.modal-regional-demand-type-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
    margin-bottom: 0px;
}

.modal-regional-demand-type-inner  .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-decoration: none !important;
}


.modal-regional-demand-result{
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 90px;
    left: 0;
    padding: 10px;
}

.modal-regional-demand-result-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
    width: 300px;
    height: 180px;
}

.modal-regional-demand-result-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
    margin-bottom: 0px;
}

.modal-regional-demand-result-inner  .column-60-per{
    float: left;
    width: 60%;
    padding: 0px;
  }
  
  .modal-regional-demand-result-inner  .column-40-per{
    float: left;
    width: 40%;
    padding: 0px;
  }

    
  .modal-regional-demand-result-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-decoration: none !important;
}

.modal-regional-demand-graph {
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 300px;
    left: 0;
    padding: 10px;
}

.modal-regional-demand-graph-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 680px;
    height: 375px;
}

.modal-regional-demand-graph-inner .nav-tabs {
    margin-top: 2px;
  }

.column-half-50{
    float: left;
    width: 50%;
    margin-top: 10px;
    text-align: center;
}

.top-od-regional{
    padding-top: 5px;
}

.top-od-regional-categories{
    padding-top: 10px;
    padding-left: 90px;
}

table.top-od-regional-categories th{
    width:150px;
}

table.top-od-regional-categories td{
    width:135px;
}

table.top-od-regional th{
    width:135px;
}

table.top-od-regional td{
    width:135px;
}

.topOD-scroll{
    width: 680px;
    height: 320px;
    overflow-y: scroll;
}

.top-tiles-employer-scroll{
    width: 460px;
    height: 250px;
    overflow-y: scroll;
    margin-bottom: 10px;
    margin-top: 10px;
}

/*Regional Analysis*/
.modal-regional-analysis-type {
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 90px;
    right: 0;
    padding: 10px;
}
     
.modal-regional-analysis-type-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
    width: 300px;
    height: 240px;
}

.modal-regional-analysis-type-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
    margin-bottom: 0px;
}

.modal-regional-analysis-type-inner  .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-decoration: none !important;
}

.modal-regional-analysis-step {
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 360px;
    right: 0;
    padding: 10px;
}
     
.modal-regional-analysis-step-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
    width: 300px;
    height: 120px;
}

.modal-regional-analysis-step-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
    margin-bottom: 0px;
}

.modal-regional-analysis-step-inner  .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-decoration: none !important;
}

.modal-regional-analysis-filters {
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 510px;
    right: 0;
    padding: 10px;
}
     
.modal-regional-analysis-filters-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
    width: 300px;
    height: 310px;
}

.modal-regional-analysis-filters-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
    margin-bottom: 0px;
}

.modal-regional-analysis-filters-inner  .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 5px;
    text-decoration: none !important;
}

.modal-regional-analysis-filters-inner .label-2{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    margin-top: 2px;
    margin-bottom: 5px;
  }

  .modal-regional-analysis-filters-inner .slider-distance{
    margin-left: 30px;
  }

  .modal-regional-analysis-filters-inner .form-check-label {
    color: #2b2b2b;
    font-family: 'Inter';
    font-style: normal;
    font-size: 13px;
}

.modal-regional-analysis-type-inner .column-50{
    float: left;
    width: 50%;
    padding: 0px;
  }

  .modal-regional-analysis-type-inner .column-40{
    float: left;
    width: 40%;
    padding: 0px;
  }


  .modal-regional-analysis-type-inner .column-20{
    float: left;
    width: 20%;
    padding: 0px;
  }

  .modal-regional-demand-graph-inner tbody tr:hover{
    background-color: #fff3fa;
    cursor: pointer;
  }