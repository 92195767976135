.map-btn-drop-point{
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 330px;
    top: 90px;
    right: 0;
    padding: 10px;
}

.map-btn-drop-point-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 307px;
    height: 150px;
}

.map-btn-drop-point-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
}

.map-btn-drop-point-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 13px;
}

.map-btn-drop-point-inner  .label-2{
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 0px;
  margin-left: 20px;
  width: 200px;
  height: 16px;
  text-align: left;
}

.generate-btn{
    background-color: rgb(2, 115, 128);
    color: #ffffff;
    font-family: Trebuchet MS;
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    padding: 14px 15px;
    border: 0px solid #000;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 6px;
   }

   .no-btn{
    background-color: rgb(2, 115, 128);
    color: #ffffff;
    font-family: Trebuchet MS;
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    padding: 14px 15px;
    border: 2px solid rgb(2, 115, 128);
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 6px;
   }

   .generate-btn-outline{
    background-color: rgb(255, 255, 255);
    color: rgb(2, 115, 128);
    font-family: Trebuchet MS;
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    padding: 14px 15px;
    border: 2px solid rgb(2, 115, 128);
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 6px;
   }

    Button:disabled {
        background-color: rgb(136, 136, 136);
        color: #ffffff;
        font-family: Trebuchet MS;
        font-size: 13px;
        font-weight: 400;
        font-style: normal;
        text-decoration: none;
        padding: 14px 15px;
        border: 0px solid #000;
        border-radius: 10px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 6px;
   }

   Button:disabled:hover {
    background-color: rgb(136, 136, 136);
    color: #ffffff;
    font-family: Trebuchet MS;
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    padding: 14px 15px;
    border: 0px solid #000;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 6px;
}

.generate-btn:hover{
    background-color: rgb(3, 59, 93);
   }

   .co2-3dview-btn{
    background-color: #e32a8d;
    color: #ffffff;
    font-family: Trebuchet MS;
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    padding: 14px 15px;
    border: 0px solid #000;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 6px;
   }

.co2-3dview-btn:hover{
    background-color: #8f1a58;
   }

   .excel-btn{
    background-color: rgb(2, 128, 27);
    color: #ffffff;
    font-family: Trebuchet MS;
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    padding: 14px 15px;
    border: 0px solid #000;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 6px;
   }

   .excel-btn:hover{
    background-color: rgb(3, 93, 21);
   }

.reset-btn{
    background-color: rgb(128, 2, 2);
    color: #ffffff;
    font-family: Trebuchet MS;
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    padding: 14px 15px;
    border: 0px solid #000;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 6px;
   }

.reset-btn:hover{
    background-color: rgb(93, 10, 3);
   }

.bg-loader {
    background-color: #f3f3f3;
    width: 100%;
    height: auto;
  }

.loader {
    border: 10px solid #f3f3f3;
    border-top: 10px solid rgb(15, 171, 171);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 

.pos-center {
    position: fixed;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
  }


  .modal-alert{
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 330px;
    top: 40%;
    right: 40%;
    padding: 10px;
}

.modal-alert-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 307px;
    height: 90px;
}

.modal-alert-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
}

.modal-alert-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 13px;
}

.slider-modal{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 330px;
  top: 180px;
  right: 0;
  padding: 10px;
}

.slider-modal-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 307px;
  height: 70px;
}

.slider-modal-inner h1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 2px;
  color: #5B5B5B;
}

.slider-modal-inner .label{
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  margin-top: 2px;
  margin-bottom: 0px;
}

.slider-modal-inner .slider-distance{
  margin-left: 30px;
}

.slider-modal-inner .form-check-label{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #5B5B5B;
}

.column-1-40{
  float: left;
  width: 40%;
  padding: 0px;
}

.column-2-60{
  float: right;
  width: 60%;
  padding: 0px;
  padding-bottom: 9px;
}

.column-1-55{
  float: left;
  width: 55%;
  padding: 0px;
}

.column-2-40{
  float: right;
  width: 40%;
  padding: 0px;
}

.column-1-60{
  float: left;
  width: 60%;
  padding: 0px;
}

.column-2-40{
  float: right;
  width: 40%;
  padding: 0px;
}

.column-33{
  float: left;
  width: 33%;
  padding: 0px;
}

.column-1-50{
  float: left;
  width: 50%;
  padding: 0px;
}

.column-2-50{
  float: right;
  width: 50%;
  padding: 0px;
}

.column-1-50c{
  float: right;
  width: 30%;
  padding-right: 70px;
}

.column-2-50c{
  float: left;
  width: 30%;
  padding-left: 70px;
}

.column-1-55{
  float: left;
  width: 55%;
  padding: 0px;
}

.column-2-45{
  float: right;
  width: 45%;
  padding: 0px;
}

.column-5{
  float: left;
  width: 8%;
}

.column-1-90{
  float: left;
  width: 90%;
  padding: 0px;
}

.column-2-10{
  float: right;
  width: 10%;
  padding: 0px;
}

.column-1-20p{
  float: left;
  width: 20%;
  padding: 0px;
}

.column-2-80p{
  float: right;
  width: 80%;
  padding: 0px;
}

.column-3-5{
  float: right;
  width: 5%;
  padding: 0px;
}

.column-1-20p10{
  float: left;
  width: 20%;
  padding: 0px;
  padding-top: 5px;
  padding-bottom: 33px;
}

.column-2-80p10{
  float: right;
  width: 80%;
  padding: 0px;
  padding-top: 5px;
}

/*Employer selector*/
.map-layer-slider-select-employer{
  position: absolute;
  top: 90px;
  left: 0px;
  padding: 10px;
}

.map-layer-slider-select-employer-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  margin-bottom: 15px;
  width: 300px;
  height: 80px;
  justify-content: center;
}

.map-layer-slider-select-employer-inner h1{
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: -10px;
}

.slider-2-modal{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 330px;
  top: 280px;
  right: 0;
  padding: 10px;
}

.slider-2-modal-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 307px;
  height: 280px;
}

.slider-2-modal-inner h1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 2px;
  color: #5B5B5B;
}

.slider-2-modal-inner .label{
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  margin-top: 2px;
  margin-bottom: 0px;
}

.slider-2-modal-inner .label-2{
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  margin-top: 2px;
  margin-bottom: 5px;
}

.slider-2-modal-inner .slider-distance{
  margin-left: 30px;
}

.slider-2-modal-inner .form-check-label{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #5B5B5B;
}

.map-btn-bus-potential{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 330px;
  top: 90px;
  right: 0;
  padding: 10px;
}

.map-btn-bus-potential-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 307px;
  height: 640px;
}

.map-btn-bus-potential-inner h1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin-top: 6px;
  color: #5B5B5B;
}

.map-btn-bus-potential-inner .label{
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 13px;
}

.map-btn-bus-potential-inner h2{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  margin-top: 6px;
  color: #5B5B5B;
}

.map-btn-bus-potential-inner .slider-distance{
  margin-left: 30px;
}

.column-2-30{
  float: right;
  width: 30%;
  padding: 0px;
}

.column-1-60{
  float: left;
  width: 60%;
  padding: 0px;
  margin-left: 30px;
}

.map-btn-tile-potential-resp-1{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 140px;
  top: 520px;
  left: 0px;
  padding: 10px;
}

.map-btn-tile-potential-resp-2{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 140px;
  top: 520px;
  left: 165px;
  padding: 10px;
}

.map-btn-tile-potential-resp-3{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 140px;
  top: 520px;
  left: 330px;
  padding: 10px;
}

.map-btn-tile-potential-resp-1-inner{
  background-color: #b6d6a7;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 130px;
  height: 80px;
}

.map-btn-tile-potential-resp-2-inner{
  background-color: #fae391;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 130px;
  height: 80px;
}

.map-btn-tile-potential-resp-3-inner{
  background-color: #e89998;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 130px;
  height: 80px;
}

.map-btn-tile-potential-resp-1-inner h3{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 18px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 10px;
  color: #2b2b2b;
}

.map-btn-tile-potential-resp-1-inner h4{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 2px;
  color: #494949;
}

.map-btn-tile-potential-resp-1-inner h5{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 2px;
  color: #494949;
}

.map-btn-tile-potential-resp-2-inner h3{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 18px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 10px;
  color: #2b2b2b;
}

.map-btn-tile-potential-resp-2-inner h4{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 2px;
  color: #494949;
}

.map-btn-tile-potential-resp-2-inner h5{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 2px;
  color: #494949;
}

.map-btn-tile-potential-resp-3-inner h3{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 18px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 10px;
  color: #2b2b2b;
}

.map-btn-tile-potential-resp-3-inner h4{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 2px;
  color: #494949;
}

.map-btn-tile-potential-resp-3-inner h5{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 2px;
  color: #494949;
}

.map-btn-bus-potential-resp{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 470px;
  top: 630px;
  left: 0;
  padding: 10px;
}

.map-btn-bus-potential-resp-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 460px;
  height: 110px;
}

.map-btn-bus-potential-resp-inner h1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 2px;
  color: #cb237d;
}

.map-btn-bus-potential-resp-inner h3{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 2px;
  color: #5B5B5B;
}

.map-btn-bus-potential-resp-inner h4{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 2px;
  color: #2b2b2b;
}

.modal-tile-params {
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 450px;
  top: 90px;
  left: 0;
  padding: 10px;
}

.modal-tile-params-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 460px;
  height: 260px;
}

.modal-tile-params-inner .nav-tabs {
  margin-top: 8px;
}

.modal-tile-params-inner h2{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  margin-top: 6px;
  color: #5B5B5B;
}

.modal-tile-params-inner .label{
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.modal-tile-params-inner .label-2{
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 5px;
}

.row-input{
  margin-bottom: 7px;
}

.map-btn-bus-potential-inner .label-2{
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.row-parameters{
  margin-bottom: 10px;
  margin-left: 10px;
}

.row-parameter-select{
  margin-top: 10px;
}

.row-parameters-3{
  margin-bottom: 10px;
  margin-left: 0px;
}


.row-parameters-2{
  margin-bottom: 10px;
  padding-bottom: 26px;
  padding-top: 5px;
  margin-left: 10px;
}

.row-parameters-special{
  margin-bottom: 10px;
  padding-bottom: 15px;
  padding-top: 5px;
  margin-left: 10px;
}

.row-parameters-4{
  margin-bottom: 10px;
  padding-bottom: 0px;
  padding-top: 5px;
  margin-left: 10px;
}

/** brain loader **/
.brain-loader{
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
/* 	border:2px solid red; */
	height:150px;
	width:150px;
}
/* path length */
/* 670.0289916992188 */

.brain-loader path {
  stroke-dasharray: 670;
  stroke-dashoffset: 670;
  animation: dash 4s linear infinite;
}

@keyframes dash {
	0%{opacity:1;}
  80% {
    stroke-dashoffset: 0;
		opacity:1;
  }
	90%{
		stroke-dashoffset: 0;
		opacity:1
	}
	100%{
		stroke-dashoffset: 0;
		opacity:0;
	}
}

.circle-loading-brain{
  width: 250px;
  height: 250px;
  background: #f3d8ed;
  border-radius: 50%;
}

.pos-center-brain-loader {
  position: fixed;
  top: calc(50% - 90px);
  left: calc(50% - 150px);
}

.map-drop-point-pkm-value{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 330px;
  top: 480px;
  left: 0;
  padding: 10px;
}

.map-drop-point-pkm-value-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 307px;
  height: 145px;
}

.map-drop-point-pkm-value-inner h1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 2px;
  color: #cb237d;
}

.map-drop-point-pkm-value-inner h3{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 2px;
  color: #232222;
}

.map-drop-point-pkm-value-inner h4{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 2px;
  color: #5B5B5B;
}

.map-btn-drop-point-ov-quality{
  font-family:'Inter', sans-serif;
  position: absolute;
  top: 90px;
  right: 0;
  padding: 10px;
}

.map-btn-drop-point-ov-quality-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 350px;
  height: 130px;
}

.map-btn-drop-point-ov-quality-inner h1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin-top: 6px;
  color: #5B5B5B;
}

.map-btn-drop-point-ov-quality-inner .label{
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.map-btn-drop-point-ov-quality-tiles-analysis{
  font-family:'Inter', sans-serif;
  position: absolute;
  top: 90px;
  left: 0;
  padding: 10px;
}

.map-btn-drop-point-ov-quality-tiles-analysis-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 460px;
  height: 220px;
}

.map-btn-drop-point-ov-quality-tiles-analysis-inner h1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin-top: 6px;
  color: #5B5B5B;
}

.map-btn-drop-point-ov-quality-tiles-analysis-inner .nav-tabs {
  margin-top: 8px;
}

.map-btn-drop-point-ov-quality-tiles-analysis-inner h2{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-top: 5px;
  color: #5B5B5B;
}

.map-btn-drop-point-ov-quality-tiles-analysis-inner .label-2{
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 5px;
}

.map-btn-drop-point-ov-quality-tiles-analysis-inner .label{
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.map-btn-drop-point-ov-quality-sliders{
  font-family:'Inter', sans-serif;
  position: absolute;
  top: 220px;
  right: 0;
  padding: 10px;
}

.map-btn-drop-point-ov-quality-sliders-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 350px;
  height: 320px;
}

.map-btn-drop-point-ov-quality-sliders-inner .label{
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 13px;
}

.map-btn-drop-point-ov-quality-sliders-inner h2{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #5B5B5B;
}

.map-btn-drop-point-ov-quality-sliders-inner .slider-distance{
  margin-left: 30px;
}

.map-btn-drop-point-ov-quality-info-display{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 350px;
  top: 90px;
  left: 0;
  padding: 10px;
}

.map-btn-drop-point-ov-quality-info-display-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 367px;
  height: 40px;
}

.map-btn-drop-point-ov-quality-info-display-inner h2{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 5px;
  color: #5B5B5B;
}


.map-btn-tile-potential-resp-1-ovq{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 150px;
  top: 160px;
  left: 0;
  padding: 10px;
}

.map-btn-tile-potential-resp-2-ovq{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 150px;
  top: 160px;
  left: 200px;
  padding: 10px;
}

.map-btn-tile-potential-resp-3-ovq{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 150px;
  top: 270px;
  left: 0;
  padding: 10px;
}

.map-btn-tile-potential-resp-4-ovq{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 150px;
  top: 270px;
  left: 200px;
  padding: 10px;
}

.map-btn-tile-potential-resp-1-ovq-inner{
  background-color: #b6d6a7;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 167px;
  height: 80px;
}

.map-btn-tile-potential-resp-2-ovq-inner{
  background-color: #fae391;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 167px;
  height: 80px;
}

.map-btn-tile-potential-resp-3-ovq-inner{
  background-color: #e89998;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 167px;
  height: 80px;
}

.map-btn-tile-potential-resp-4-ovq-inner{
  background-color: #94c6c7;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 167px;
  height: 80px;
}

.map-btn-tile-potential-resp-1-ovq-inner h3{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 18px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 10px;
  color: #2b2b2b;
}

.map-btn-tile-potential-resp-1-ovq-inner h4{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 2px;
  color: #494949;
}

.map-btn-tile-potential-resp-1-ovq-inner h5{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 2px;
  color: #494949;
}

.map-btn-tile-potential-resp-2-ovq-inner h3{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 18px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 10px;
  color: #2b2b2b;
}


.map-btn-tile-potential-resp-2-ovq-inner h4{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 2px;
  color: #494949;
}


.map-btn-tile-potential-resp-2-ovq-inner h5{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 2px;
  color: #494949;
}

.map-btn-tile-potential-resp-3-ovq-inner h3{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 18px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 10px;
  color: #2b2b2b;
}


.map-btn-tile-potential-resp-3-ovq-inner h4{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 2px;
  color: #494949;
}

.map-btn-tile-potential-resp-3-ovq-inner h5{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 2px;
  color: #494949;
}

.map-btn-tile-potential-resp-4-ovq-inner h3{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 18px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 10px;
  color: #2b2b2b;
}


.map-btn-tile-potential-resp-4-ovq-inner h4{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 2px;
  color: #494949;
}

.map-btn-tile-potential-resp-4-ovq-inner h5{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 2px;
  color: #494949;
}

.map-btn-drop-point-info-display{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 470px;
  top: 380px;
  left: 0;
  padding: 10px;
}

.map-btn-drop-point-info-display-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 460px;
  height: 40px;
}

.map-btn-drop-point-info-display-inner h2{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 5px;
  color: #5B5B5B;
}

.map-btn-drop-point-info-category{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 470px;
  top: 450px;
  left: 0;
  padding: 10px;
}

.map-btn-drop-point-info-category-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 460px;
  height: 40px;
}

.map-btn-drop-point-info-category-inner h2{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 5px;
  color: #5B5B5B;
}

.map-btn-bus-potential-inner .column-1-40{
  float: left;
  width: 45%;
  padding: 0px;
  padding-bottom: 9px;
}

.map-btn-bus-potential-inner .column-2-60{
  float: right;
  width: 55%;
  padding: 0px;
  padding-bottom: 9px;
}

/*3D Button*/
.map-graph-button-modal{
  position: absolute;
  top: 690px;
  padding-right: 15px;
  right: 0;
}

.map-graph-button-modal-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 2px;
  padding-top: 10px;
  margin-bottom: 15px;
  width: 40px;
  height: 35px;
  justify-content: center;
}

.modal-graph-drop-point {
  font-family:'Inter', sans-serif;
  position: absolute;
  top: 90px;
  left: 0;
  padding: 10px;
}

.modal-graph-drop-point-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 600px;
  height: 360px;
}

.modal-graph-drop-point-inner .nav-tabs{
  margin-top: 0px;
}

.modal-graph-drop-point-tile {
  font-family:'Inter', sans-serif;
  position: absolute;
  top: 90px;
  left: 0;
  padding: 10px;
}

.modal-graph-drop-point-tile-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 600px;
  height: 360px;
}

.modal-graph-drop-point-tile-inner .nav-tabs{
  margin-top: 0px;
}


/*3D Button*/
.map-graph-button-modal-hotspot{
  position: absolute;
  top: 100px;
  padding-right: 15px;
  right: 375px;
}

.map-graph-button-modal-hotspot-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 2px;
  padding-top: 10px;
  margin-bottom: 15px;
  width: 40px;
  height: 35px;
  justify-content: center;
}

/*Tile Selector*/
.map-graph-button-tile-selector{
  position: absolute;
  top: 100px;
  padding-left: 10px;
  left: 0;
}

.map-graph-button-tile-selector-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 2px;
  padding-top: 10px;
  margin-bottom: 15px;
  width: 40px;
  height: 35px;
  justify-content: center;
}

.modal-graph-tile-selector{
  font-family:'Inter', sans-serif;
  position: absolute;
  top: 140px;
  left: 0;
  padding: 10px;
}

.modal-graph-tile-selector-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 600px;
  height: 350px;
}

.modal-graph-tile-selector-inner .nav-tabs{
  margin-top: 0px;
}

.drop-point-hotspot-params-inner .no-data{
  font-family: 'Inter';
  font-style: normal;
  font-size: small;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  margin-top: 10px;
  color: #6f6f6f;
}

.modal-btn-tile-selector-filters{
  font-family:'Inter', sans-serif;
  position: absolute;
  top: 250px;
  right: 0;
  padding: 10px;
}

.modal-btn-tile-selector-filters-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 350px;
  height: 200px;
}

.modal-btn-tile-selector-filters-inner h1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin-top: 6px;
  color: #5B5B5B;
}

.modal-btn-tile-selector-filters-inner .label{
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.modal-btn-tile-selector-filters-inner .column-1-40{
  float: left;
  width: 40%;
  padding: 0px;
  padding-bottom: 9px;
}

.modal-btn-tile-selector-filters-inner .column-2-60{
  float: right;
  width: 60%;
  padding: 0px;
  padding-bottom: 9px;
}

.modal-btn-tile-selector-filters-inner .slider-distance {
  margin-left: 30px;
}

.tile-selector-excel-inner h1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 10px;
  color: #5B5B5B;
}

.tile-selector-excel-inner tr:hover{
  background-color: #defffc;
  cursor: pointer;
}

.tile-selector-excel-inner .title:hover{
  background-color: #808080;
  cursor: default;
}

.tile-selector-excel-inner .title{
  background-color: #808080;
  cursor: default;
}

.tile-selector-excel-inner table, .tile-selector-excel-inner th, .tile-selector-excel-inner td {
  border: 1px solid;
  border-collapse: collapse;
  border-color: #5B5B5B;
}

.label-title {
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.table-center-marketing {
  margin-left: auto;
  margin-right: auto;
}

/* Winners Losers Detail */

.modal-hypothesis-ms{
  font-family:'Inter', sans-serif;
  position: absolute;
  top: 200px;
  right: 0px;
  padding: 10px;
}

.modal-hypothesis-ms-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 630px;
  height: 200px;
}

.modal-hypothesis-ms-inner h1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin-top: 6px;
  color: #5B5B5B;
}

.modal-hypothesis-ms-inner .label{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  color: #5B5B5B;
}

.modal-hypothesis-ms-inner .label-2{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 0px;
  color: #5B5B5B;
}

.modal-hypothesis-ms-inner .label-text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
  color: #5B5B5B;
}

.modal-new-tag{
  font-family:'Inter', sans-serif;
  position: absolute;
  top: 515px;
  left: 0px;
  padding: 10px;
}

.modal-new-tag-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 500px;
  height: 200px;
}

.btn-row{
  margin-bottom: 10px;
  margin-left: 10px;
  padding-top: 10px;
  height: 20px;
}

.modal-openAI-response{
  font-family:'Inter', sans-serif;
  position: absolute;
  top: 740px;
  left: 0px;
  padding: 10px;
}

.modal-openAI-response-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 500px;
  height: 150px;
}

.modal-openAI-response-inner .label{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
  color: #5B5B5B;
}

.modal-graph-tile-selector-ms{
  font-family:'Inter', sans-serif;
  position: absolute;
  top: 430px;
  right: 50px;
  padding: 10px;
}

.modal-graph-tile-selector-ms-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 580px;
  height: 350px;
}

.modal-graph-tile-selector-ms-inner .nav-tabs{
  margin-top: 0px;
}

.map-graph-button-winner-loser{
  position: absolute;
  top: 440px;
  padding-left: 10px;
  right: 10px;
}

.map-graph-button-winner-loser-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 2px;
  padding-top: 10px;
  margin-bottom: 15px;
  width: 40px;
  height: 35px;
  justify-content: center;
}

.modal-hypothesis-list-ms{
  font-family:'Inter', sans-serif;
  position: absolute;
  top: 90px;
  right: 0px;
  padding: 10px;
}

.modal-hypothesis-ms-list-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 630px;
  height: 80px;
}

.modal-hypothesis-ms-list-inner h1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin-top: 6px;
  color: #5B5B5B;
}

.modal-hypothesis-ms-list-inner .label{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  color: #5B5B5B;
}

.map-category-tile{
  font-family:'Inter', sans-serif;
  position: absolute;
  top: 330px;
  right: 0;
  padding: 10px;
}

.map-category-tile-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 350px;
  height: 130px;
}

.map-category-tile-inner h1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin-top: 6px;
  color: #5B5B5B;
}

.map-graph-button-regional-analysis{
  position: absolute;
  top: 100px;
  padding-right: 15px;
  left: 340px;
}

.map-graph-button-regional-analysis-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 2px;
  padding-top: 10px;
  margin-bottom: 15px;
  width: 40px;
  height: 35px;
  justify-content: center;
}

/*Potential Tile 2D*/
.modal-potential-tile-params {
  font-family:'Inter', sans-serif;
  position: absolute;
  top: 90px;
  left: 0;
  padding: 10px;
}

.modal-potential-tile-params-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 460px;
  height: 220px;
}

.modal-potential-tile-params-inner .nav-tabs {
  margin-top: 8px;
}

.modal-potential-tile-params-inner h2{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  margin-top: 6px;
  color: #5B5B5B;
}

.modal-potential-tile-params-inner .label{
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.modal-potential-tile-params-inner .label-2{
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 5px;
}

.map-potential-btn-drop-point-info-display{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 470px;
  top: 340px;
  left: 0;
  padding: 10px;
}

.map-potential-btn-drop-point-info-display-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 460px;
  height: 40px;
}

.map-potential-btn-drop-point-info-display-inner h2{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 5px;
  color: #5B5B5B;
}

.map-potential-btn-drop-point-info-category{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 470px;
  top: 410px;
  left: 0;
  padding: 10px;
}

.map-potential-btn-drop-point-info-category-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 460px;
  height: 40px;
}

.map-potential-btn-drop-point-info-category-inner h2{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 5px;
  color: #5B5B5B;
}

.map-potential-btn-tile-potential-resp-1{
  font-family:'Inter', sans-serif;
  position: absolute;
  top: 480px;
  left: 0px;
  padding: 10px;
}

.map-potential-btn-tile-potential-resp-2{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 140px;
  top: 480px;
  left: 165px;
  padding: 10px;
}

.map-potential-btn-tile-potential-resp-3{
  font-family:'Inter', sans-serif;
  position: absolute;
  top: 480px;
  left: 330px;
  padding: 10px;
}

.map-b2b-modal-split{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 470px;
  top: 630px;
  left: 0;
  padding: 10px;
}

.map-b2b-modal-split-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 460px;
  height: 20px;
}

.map-b2b-modal-split-inner h1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 5px;
  color: #5B5B5B;
}

.map-b2b-modal-split-inner h2{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 5px;
  color: #5B5B5B;
}


.map-data-from{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 470px;
  top: 630px;
  left: 0;
  padding: 10px;
}

.map-data-from-employers{
  font-family:'Inter', sans-serif;
  position: absolute;
  right: 0px;
  padding: 10px;
}

.map-data-from-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 460px;
  height: 15px;
}

.map-data-from-inner p{
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 5px;
  color: #5B5B5B;
}

.map-mobilityabo-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 460px;
  height: 180px;
}

.map-mobilityabo-inner .label{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 5px;
  color: #5B5B5B;
}

.map-mobilityabo-inner .label-1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 5px;
  color: #5B5B5B;
}

.modal-potential-employer-tile-table {
  font-family:'Inter', sans-serif;
  position: absolute;
  top: 90px;
  right: 0px;
  padding: 10px;
}

.modal-potential-employer-tile-table-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 460px;
  height: 420px;
}

.modal-potential-employer-tile-table-inner h1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  margin-top: 6px;
  color: #5B5B5B;
}

.modal-potential-employer-tile-table-inner h3{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  margin-top: 6px;
  color: #5B5B5B;
}

/*Map Potential Employer 2D*/
.modal-potential-tile-employer-params {
  font-family:'Inter', sans-serif;
  position: absolute;
  top: 520px;
  left: 0px;
  padding: 10px;
}


.map-b2b-ranking-employer{
  font-family:'Inter', sans-serif;
  position: absolute;
  top: 460px;
  left: 50px;
  padding: 10px;
}

.map-b2b-ranking-employer-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 410px;
  height: 27px;
}

.map-b2b-ranking-employer-inner h2{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 5px;
  color: #5B5B5B;
}

.map-b2b-ranking-employer-inner p{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 5px;
  color: #5B5B5B;
}

.modal-potential-employer-tile-table-inner .nav-tabs {
  margin-top: 5px;
}


.modal-potential-change-name-companies {
  font-family:'Inter', sans-serif;
  position: absolute;
  top: 410px;
  left: 320px;
  padding: 10px;
}

.modal-potential-change-name-companies-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 0px;
  width: 250px;
  height: auto;
}


.modal-potential-change-name-companies-inner h1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin: 0px;
  padding: 0px;
  color: #5B5B5B;
}

.column-1-50-m{
  float: left;
  width: 25%;
  padding-left: 25%;
}

.column-2-50-m{
  float: right;
  width: 25%;
  padding-right: 25%;
}

/* Potential 2D */
.map-potential-btn-tile-potential2D-resp-1{
  font-family:'Inter', sans-serif;
  position: absolute;
  top: 480px;
  left: 0px;
  padding: 10px;
}

.map-potential-btn-tile-potential2D-resp-2{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 140px;
  top: 500px;
  left: 0px;
  padding: 10px;
}

.map-potential-btn-tile-potential2D-resp-3{
  font-family:'Inter', sans-serif;
  position: absolute;
  top: 480px;
  left: 160px;
  padding: 10px;
}

.map-btn-tile-potential2D-resp-1-inner{
  background-color: #b6d6a7;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 130px;
  height: 150px;
}

.map-btn-tile-potential2D-resp-2-inner{
  background-color: #fae391;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 130px;
  height: 150px;
}

.map-btn-tile-potential2D-resp-3-inner{
  background-color: #e89998;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 130px;
  height: 150px;
}

.map-btn-tile-potential2D-resp-1-inner h3{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 31px;
  line-height: 20px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #04601B;
}

.map-btn-tile-potential2D-resp-1-inner h4{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 2px;
  color: #04601B;
}

.map-btn-tile-potential2D-resp-2-inner h3{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 31px;
  line-height: 20px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #603604;
}

.map-btn-tile-potential2D-resp-2-inner h4{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 2px;
  color: #603604;
}

.map-btn-tile-potential2D-resp-3-inner h3{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 31px;
  line-height: 20px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #600404;
}

.map-btn-tile-potential2D-resp-3-inner h4{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 2px;
  color: #600404;
}

/*Double Progress Bar*/
.miniBarProgress1 {
  background-color: #B9196D;
  height: 100%;
  position: absolute;
  top: 0rem;
  left: 0rem;
}

.miniBarProgress2 {
  background-color: #00BD50;
  height: 100%;
  position: absolute;
  top: 0rem;
}

.miniBar {
  border: 0px solid #8a898a;
  background-color: #F196C5;
  position: relative;
  width: 100% ;
}

.custom-container {
  width: 100%;
}

.custom-container .rmdp-input {
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-top: 0px;
  color: #5B5B5B;
  box-shadow: 0 0 0px rgba(0, 0, 0, 0.15);
}

input::file-selector-button {
    background-color: rgb(157, 3, 98);
    color: #ffffff;
    font-family: Trebuchet MS;
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    border: 0px solid #000;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 30px;
}
