.table-modal-split {
    margin:auto;
    table-layout:fixed;
    padding:0px;
    text-align:center;
    vertical-align:middle;
}

.span-bold {
    font-weight: bold;
}

.p-no-margin {
    margin-block-end: 0em;
}

.header-modal{
    margin-block-start:0px;
    margin-block-end:0px;
}

.p-time-difference{
    color: black; 
    font-weight: bold;
}

.p-secondary-text{
    color: gray; 
    font-size: 11px;
}

.p-no-margin-end{
    margin-block-end: 0em;
}

.p-no-margin-start{
    margin-block-start:0px;
}

.tooltip {
    pointer-events: none; 
    position: absolute; 
    z-index: 9; 
    font-size: 12px; 
    padding: 8px; 
    background: #000; 
    color: #fff; 
    min-width: 160px; 
    max-height: 100%; 
}

.tooltip.interactive {
    pointer-events: all; 
    background: #fcfcfc; 
    color: #444; 
    max-width: 250px;
    margin: auto;
}

.tooltip.interactive_heatmap {
    pointer-events: all; 
    background: #fcfcfc; 
    color: #444; 
    max-width: 200px;
    min-height: 65px; 
}

/*Gradient convection*/

.map-layer-convention-insights{
    position: absolute;
    bottom: 625px;
    right: 3px;
    padding-right: 10px;

}

.map-layer-convention-insights-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 5px;
    margin-bottom: 15px;
    width: 273px;
    height: 40px;
    justify-content: center;
}

.map-layer-convention-insights-inner .label-convention{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.map-card-insight-analyzer-inner .insight-analyzer-modal-slider-inner{
    margin-left: 20px;
}

/* Create three columns that floats next to each other */
.map-layer-convention-insights-inner .column-1 {
    float: left;
    width: 22%;
    text-align: center;
    margin-top: 8px;
  }
.map-layer-convention-insights-inner .column-2 {
    float: left;
    width: 50%;
    text-align: center;
    margin-top: 14px;
  }
.map-layer-convention-insights-inner .column-3 {
    float: left;
    width: 28%;
    text-align: center;
    margin-top: 8px;
  }

.map-layer-convention-insights-inner .convention-linear-insights{
    background: #0FABAB;
    background: linear-gradient(0.25turn, #0FABAB, #F78948);
    height: 10px;
    width: 100%;
    bottom: 0;
}

/*Mobility Origin - Destination*/
.mobility-insights-origin-destionation {
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 280px;
    bottom: 400px;
    right: 0;
    padding: 10px;
}
     
.mobility-insights-origin-destionation-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 257px;
    height: 180px;
}

.mobility-insights-origin-destionation-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
}

.mobility-insights-origin-destionation-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}


/*Mobility Insights*/
.map-card-insight-analyzer-modal {
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 280px;
    bottom: 0px;
    right: 0;
    padding: 10px;
}
     
.map-card-insight-analyzer-modal-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
    width: 257px;
    height: 370px;
}

.map-card-insight-analyzer-modal-inner-scroll {
    width: 257px;
    height: 340px;
    overflow-y: scroll;
}

.map-card-insight-analyzer-modal-inner .label-selected-value{
    font-size: 10px;
    text-align: center;
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
}

.map-card-insight-analyzer-modal-inner .insight-analyzer-modal-slider-inner{
    margin-left: 20px;
}

.map-card-insight-analyzer-modal-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 6px;
    color: #5B5B5B;
}

.map-card-insight-analyzer-modal-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

/*3D Button*/
.map-3d-button-modal{
    position: absolute;
    bottom: 630px;
    padding-right: 15px;
    right: 0;
  }
  
  .map-3d-button-modal-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 10px;
    margin-bottom: 15px;
    width: 40px;
    height: 35px;
    justify-content: center;
  }

  /*Mobility Insights*/
.map-card-insight-analyzer-modal-3d {
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 280px;
    bottom: 0px;
    right: 0;
    padding: 10px;
}
     
.map-card-insight-analyzer-modal-3d-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 257px;
    height: 250px;
}

.map-card-insight-analyzer-modal-3d-inner .label-selected-value{
    font-size: 10px;
    text-align: center;
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
}

.map-card-insight-analyzer-modal-3d-inner .insight-analyzer-modal-slider-inner{
    margin-left: 20px;
}

.map-card-insight-analyzer-modal-3d-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
}

.map-card-insight-analyzer-modal-3d-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

/*Mobility Origin - Destination*/

.mobility-insights-origin-destionation-3d {
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 280px;
    bottom: 280px;
    right: 0;
    padding: 10px;
}
     
.mobility-insights-origin-destionation-3d-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 257px;
    height: 180px;
}

.mobility-insights-origin-destionation-3d-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
}

.mobility-insights-origin-destionation-3d-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

/*2D Button*/
.map-2d-button-modal{
    position: absolute;
    bottom: 500px;
    padding: 15px;
    right: 0;
  }

  .map-2d-button-modal-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 10px;
    margin-bottom: 65px;
    width: 40px;
    height: 35px;
    justify-content: center;
  }

  /*Gradient convection*/

.map-layer-convention-3d-insights{
    position: absolute;
    bottom: 500px;
    right: 3px;
    padding: 10px;

}

.map-layer-convention-3d-insights-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 5px;
    margin-bottom: 15px;
    width: 273px;
    height: 40px;
    justify-content: center;
}

.map-layer-convention-3d-insights-inner .label-convention{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.map-card-insight-analyzer-inner .insight-analyzer-modal-slider-inner{
    margin-left: 20px;
}

/* Create three columns that floats next to each other */
.map-layer-convention-3d-insights-inner .column-1 {
    float: left;
    width: 22%;
    text-align: center;
    margin-top: 8px;
  }
.map-layer-convention-3d-insights-inner .column-2 {
    float: left;
    width: 50%;
    text-align: center;
    margin-top: 14px;
  }
.map-layer-convention-3d-insights-inner .column-3 {
    float: left;
    width: 28%;
    text-align: center;
    margin-top: 8px;
  }

.map-layer-convention-3d-insights-inner .convention-linear-insights{
    background: #0FABAB;
    background: linear-gradient(0.25turn, #0FABAB, #F78948);
    height: 10px;
    width: 100%;
    bottom: 0;
}

.css-14el2xx-placeholder {
    color: hsl(0, 0%, 50%);
    grid-area: 1/1/2/3;
    margin-left: 2px;
    margin-right: 2px;
    box-sizing: border-box;
    text-align: left;
}

.map-month-year-picker{
    position: absolute;
    top: 18px;
    left: 300px;
    width: 250px;
    height: 35px;
}

.map-month-year-picker-inner{
    border-radius: 10px;
    padding: 5px;
    width: 250px;
    height: 35px;
    justify-content: center;
    border-width: 0px;
  }


.map-division-picker{
    position: absolute;
    top: 18px;
    left: 30px;
    width: 250px;
    height: 35px;
}

.map-division-picker-inner{
    border-radius: 10px;
    padding: 5px;
    width: 250px;
    height: 35px;
    justify-content: center;
    border-width: 0px;
  }

  .map-group-picker{
    position: absolute;
    top: 18px;
    left: 570px;
    width: 250px;
    height: 35px;
}

.map-group-picker-inner{
    border-radius: 10px;
    padding: 5px;
    width: 250px;
    height: 35px;
    justify-content: center;
    border-width: 0px;
  }

/*Mobility Insights - GRAPH INSIGHTS*/
.map-card-insight-analyzer-modal-gi {
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 280px;
    bottom: 0px;
    right: 0;
    padding: 10px;
}
     
.map-card-insight-analyzer-modal-gi-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
    width: 257px;
    height: 150px;
}

.map-card-insight-analyzer-modal-gi-inner-scroll {
    width: 257px;
    height: 340px;
    overflow-y: scroll;
}

.map-card-insight-analyzer-modal-gi-inner .label-selected-value{
    font-size: 10px;
    text-align: center;
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
}

.map-card-insight-analyzer-modal-gi-inner .insight-analyzer-modal-slider-inner{
    margin-left: 20px;
}

.map-card-insight-analyzer-modal-gi-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 6px;
    color: #5B5B5B;
}

.map-card-insight-analyzer-modal-gi-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

/*Mobility OriginDestination - GRAPH INSIGHTS*/
.mobility-insights-origin-destionation-gi {
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 280px;
    bottom: 180px;
    right: 0;
    padding: 10px;
}
     
.mobility-insights-origin-destionation-gi-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 257px;
    height: 180px;
}

.mobility-insights-origin-destionation-gi-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
}

.mobility-insights-origin-destionation-gi-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.modal-accordion {
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 695px;
    top: 90px;
    left: 0;
    padding: 10px;
}

.modal-accordion-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 695px;
    height: 430px;
}

.modal-accordion-inner .column-3{
    float: left;
    width: 50%;
    margin-top: 10px;
    text-align: center;
}

.modal-accordion-inner .nav-tabs{
    margin-top: 0px;
}
  
.modal-accordion-inner .no-data{
    font-family: 'Inter';
    font-style: normal;
    font-size: small;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    margin-top: 10px;
    color: #6f6f6f;
  }
  
.modal-accordion-inner h4{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    text-align: center;
    margin-top: 4px;
    margin-bottom: 3px;
    font-size: small;
    color: #5B5B5B;
  }

.top-od{
    padding-top: 50px;
}

table.top-od th{
    width:135px;
}

table.top-od td{
    width:135px;
}

.table-title-od{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
}

.table-title-regional-od{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
}

.table-data-regional-od{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
}

.table-data-od{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

/*Modal change between weekdays and weekends*/
.modal-weekdays-weekends{
    position: absolute;
    bottom: 630px;
    padding-right: 15px;
    right:50px;
  }
  
  .modal-weekdays-weekends-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 3px;
    margin-bottom: 15px;
    width: 220px;
    height: 42px;
    justify-content: center;
  }

  .modal-weekdays-weekends-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    margin-top: 0px;
    margin-bottom:3px;
}

/* Create two equal columns that floats next to each other */
.modal-weekdays-weekends-inner .column {
    float: left;
    width: 50%;
    padding: 0px;
  }
  
  /* Clear floats after the columns */
.modal-weekdays-weekends-inner .row:after {
    content: "";
    display: table;
    clear: both;
  }

/* Modal asking to combine OD */
.modal-question-combine-od{
    position: absolute;
    bottom: 410px;
    padding-right: 15px;
    right:0px;
  }
  
  .modal-question-combine-od-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 3px;
    margin-bottom: 15px;
    width: 270px;
    height: 72px;
    justify-content: center;
  }

  .modal-question-combine-od-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    margin-top: 0px;
    margin-bottom:3px;
}

.column-1-eq {
    float: left;
    width: 50%;
    padding: 0px;
  }

  .column-2-eq {
    float: right;
    width: 50%;
    padding: 0px;
  }

/*Point-to-Point Origin - Destination*/
.point-to-point-origin-destionation {
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 280px;
    top: 85px;
    right: 0;
    padding: 10px;
}
     
.point-to-point-origin-destionation-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 257px;
    height: 180px;
}

.point-to-point-origin-destionation-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
}

.point-to-point-origin-destionation-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

/*Point-to-Point Day of Week - Hour of the Day*/
.point-to-point-dayweek-time {
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 280px;
    top: 301px;
    right: 0;
    padding: 10px;
}
     
.point-to-point-dayweek-time-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 257px;
    height: 180px;
}

.point-to-point-dayweek-time-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
}

.point-to-point-dayweek-time-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.map-layer-about-this{
    position: absolute;
    top: 85px;
    right: 0px;
    padding: 10px;

}

.map-layer-about-this .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none !important;
}

.map-layer-about-this-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 10px;
    margin-bottom: 15px;
    width: 42px;
    height: 35px;
    justify-content: center;
}

.map-layer-options-tyles{
    position: absolute;
    bottom: 0;
    left: 50px;
    padding: 10px;
}

.map-layer-about-this-detail{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-top: 0px;
    margin-left: 396px;
    width: 80px;
    height: 47px;
    justify-content: center;
}

.map-layer-slider-percentage{
    position: absolute;
    top: 180px;
    left: 0px;
    padding: 10px;
}

.map-layer-slider-percentage .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none !important;
    margin-left: -15px;
}

.map-layer-slider-percentage-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-left: 20px;
    padding-top: 10px;
    margin-bottom: 15px;
    width: 300px;
    height: 150px;
    justify-content: center;
}

.map-layer-slider-percentage-inner h1{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 9px;
    margin-left: -10px;
}

.map-layer-aggreation-type{
    position: absolute;
    top: 90px;
    left: 0px;
    padding: 10px;
}

.map-layer-aggreation-type .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none !important;
}

.map-layer-aggreation-type-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    margin-bottom: 15px;
    width: 300px;
    height: 70px;
    justify-content: center; 
}

.map-layer-aggreation-type-inner h1{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 9px;
}

/*CSS for What if layers*/
.map-layer-slider-percentage-ms{
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: 10px;
}

.map-layer-slider-percentage-ms .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none !important;
}

.map-layer-slider-percentage-ms-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-left: 20px;
    padding-top: 10px;
    margin-bottom: 15px;
    width: 300px;
    height: 70px;
    justify-content: center;
}

.map-layer-slider-percentage-ms-inner h1{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 9px;
}

.map-layer-agg-division-ms{
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: 10px;
}

.map-layer-agg-division-ms .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none !important;
}

.map-layer-agg-division-ms-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    margin-bottom: 15px;
    width: 300px;
    height: 260px;
    justify-content: center; 
}

.map-layer-agg-division-ms-inner h1{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    margin-top: 3px;
}

.map-layer-agg-division-ms-inner .title-destination
{
    margin-bottom: 9px;
}

.map-layer-agg-division-ms-inner .row{
    margin-top: 10px;
}

.slider-centering{
 margin-left: 20px;
}

.map-contributions{
    position: absolute;
    bottom: 290px;
    right: 0px;
    padding-right: 10px;
}

.map-contributions-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 5px;
    margin-bottom: 15px;
    width: 315px;
    height: 120px;
    justify-content: center;
}

.map-contributions-inner h1{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    margin-top: 3px;
}

.bg-loader {
    background-color: #f3f3f3;
    width: 100%;
    height: auto;
  }

.loader {
    border: 10px solid #f3f3f3;
    border-top: 10px solid rgb(15, 171, 171);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 

  .column-1-80 {
    float: left;
    width: 80%;
    padding: 0px;
  }

  .column-1-80-especial {
    float: left;
    width: 60%;
    padding: 0px;
    margin-left: 30px;
  }

  .column-especial {
    float: left;
    width: 100%;
    padding: 0px;
    margin-left: 30px;
  }


  .column-2-20-especial {
    float: right;
    width: 35%;
    padding: 0px;
  }

  .column-2-20 {
    float: right;
    width: 20%;
    padding: 0px;
  }

  .map-top-x-contributors{
    position: absolute;
    top: 90px;
    right: 0px;
    padding: 10px;
}

.map-top-x-contributors .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none !important;
    margin-left: -15px;
}

.map-top-x-contributors-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-left: 20px;
    padding-top: 10px;
    margin-bottom: 15px;
    width: 370px;
    height: 250px;
    justify-content: center;
}

.map-top-x-contributors-inner-scroll {
    width: 370px;
    height: 200px;
    overflow-y: scroll;
}

.map-top-x-contributors-inner h1{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 9px;
    margin-left: -10px;
}

/*Kanton selector*/
.map-layer-slider-select-kanton{
    position: absolute;
    top: 350px;
    left: 0px;
    padding: 10px;
}

.map-layer-slider-select-kanton-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    margin-bottom: 15px;
    width: 300px;
    height: 80px;
    justify-content: center;
}

.map-layer-slider-select-kanton-inner h1{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: -10px;
}

.table-data-total{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

/*Modal change between austria and switzerland*/
.modal-austria{
    position: absolute;
    bottom: 690px;
    padding-right: 15px;
    right:0px;
  }
  
  .modal-austria-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 3px;
    margin-bottom: 15px;
    width: 270px;
    height: 42px;
    justify-content: center;
  }

  .modal-austria-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    margin-top: 0px;
    margin-bottom:3px;
}

/* Create two equal columns that floats next to each other */
.modal-austria-inner .column {
    float: left;
    width: 50%;
    padding: 0px;
  }
  
  /* Clear floats after the columns */
.mmodal-austria-inner .row:after {
    content: "";
    display: table;
    clear: both;
  }


  .map-modal-enterprise{
    position: absolute;
    top: 90px;
    left: 0px;
    padding: 10px;
}

.map-modal-enterprise-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    margin-bottom: 15px;
    width: 530px;
    height: 170px;
    justify-content: center;
}

.map-modal-enterprise-inner h1{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: -10px;
}

.map-modal-enterprise-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 5px;
    margin-left: -10px;
}

.map-modal-enterprise-inner .logo-img{
    height: 80px;
    padding-right: 10px;
}

.map-modal-enterprise-distribution{
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 530px;
    top: 280px;
    left: 0;
    padding: 10px;
  }
  
  .map-modal-enterprise-distribution-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 530px;
    height: 40px;
  }
  
  .map-modal-enterprise-distribution-inner h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 5px;
    color: #5B5B5B;
  }

  .column-enteprise-4-eq{
    float: left;
    padding: 0px;
  }

  .column-enteprise-4-equal{
    float: left;
    padding: 0px;
    margin-bottom: 10px;
  }

  .map-modal-enterprise-total-filtered{
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 350px;
    left: 0px;
    padding: 10px;
  }
  
  .map-modal-enterprise-total-filtered-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 530px;
    height: 70px;
  }
  
  .map-modal-enterprise-total-filtered-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 5px;
    color: #9c1f6c;
  }
  
  .map-modal-enterprise-total-filtered-inner h3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 2px;
    color: #232222;
  }
  
  .map-modal-enterprise-total-filtered-inner h4{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 5px;
    color: #5B5B5B;
  }

  /*Potential 3D*/
  .map-potential-3D{
    position: absolute;
    top: 90px;
    left: 0px;
    padding: 10px;
}

  .map-potential-3D-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    margin-bottom: 15px;
    width: 700px;
    height: 240px;
    justify-content: center;
}

.map-potential-3D-inner h1{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: -10px;
}

.map-potential-3D-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 5px;
    margin-left: -10px;
    text-decoration: underline dotted;
}

.map-potential-3D-inner .label-text{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

.map-potential-3D-inner h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 5px;
    color: #5B5B5B;
  }

  .map-potential-3D-btn{
    position: absolute;
    top: 90px;
    left: 0px;
    padding: 10px;
}

  .map-potential-3D-btn-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    margin-bottom: 15px;
    width: 200px;
    height: 80px;
    justify-content: center;
}

.map-potential-3D-convection{
    position: absolute;
    top: 90px;
    left: 0px;
    padding: 10px;
}

.map-potential-color-scheme-3D{
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 140px;
    right: 0px;
    padding: 10px;
  }
  
  .map-potential-color-scheme-3D-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 590px;
    height: 120px;
  }
  
  .map-potential-color-scheme-3D-inner h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 5px;
    color: #5B5B5B;
  }

  .map-potential-color-scheme-3D-inner .label-2{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-top: 2px;
    margin-bottom: 0px;
    margin-left: 30px;
    width: 550px;
    height: 16px;
    text-align: left;
  }

  .map-potential-color-scheme-3D-inner .square {
    height: 16px;
    width: 16px;
  }

  .map-potential-total-filtered-value{
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 350px;
    padding: 10px;
  }
  
  .map-potential-total-filtered-value-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 307px;
    height: 145px;
  }
  
  .map-potential-total-filtered-value-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 2px;
    color: #9c1f6c;
  }
  
  .map-potential-total-filtered-value-inner h3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 2px;
    color: #232222;
  }
  
  .map-potential-total-filtered-value-inner h4{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 2px;
    color: #5B5B5B;
  }

  .potential-slider-padding{
    margin-left: 40px;
    margin-bottom: 40px;
}

.modal-information{
    font-family:'Inter', sans-serif;
    position: absolute;
    padding: 10px;
  }
  
  .modal-information-inner{
    background-color: #ffffff;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    height: auto;
  }

  .modal-information-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    margin-top: 2px;
    margin-bottom: 0px;
    margin-left: 0px;
    text-align: center;
  }

  /*Heatmap 3D*/
  .map-heatmap-3D{
    position: absolute;
    top: 90px;
    left: 0px;
    padding: 10px;
}

  .map-heatmap-3D-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    margin-bottom: 15px;
    width: 350px;
    height: 100px;
    justify-content: center;
}

.map-heatmap-3D-inner h1{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: -10px;
}

.map-heatmap-3D-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 5px;
    margin-left: -10px;
    text-decoration: underline dotted;
}

.map-heatmap-3D-inner h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 5px;
    color: #5B5B5B;
  }

  .map-heatmap-3D-bottom{
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: 10px;
}

  .map-heatmap-3D-bottom-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    margin-bottom: 15px;
    width: 750px;
    height: 100px;
    justify-content: center;
}

.map-heatmap-3D-bottom-inner h1{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: -10px;
}

.map-heatmap-3D-bottom-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 5px;
    margin-left: -10px;
    text-decoration: underline dotted;
}

.map-heatmap-3D-bottom-inner h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 5px;
    color: #5B5B5B;
  }

  .map-heatmap-3D-bottom-inner  .rc-slider {
    width: 100%;
}

  .slider-heatmap-3d{
    margin-left: 30px;
    width: 700px;
}

/*Event details*/

.map-event-details{
    position: absolute;
    top: 100px;
    left: 0px;
    padding: 10px;
}

  .map-event-details-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    margin-bottom: 15px;
    width: 350px;
    height: 160px;
    justify-content: center;
}

.map-event-details-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 5px;
    margin-left: -10px;
}

.map-event-details-inner h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 5px;
    color: #5B5B5B;
  }

.table-separation{
    margin-block-start: 0px;
    margin-block-end: 0px;
}