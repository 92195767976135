/*Navegation Bar*/

.map-navbar{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.map-navbar .map-navbar-inner{
    background: rgba(247, 247, 247, 0.9);
    height: 90px;
}

.map-navbar-inner .map-nabar-logo{
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    padding-right: 0px;
    padding-right: 0px !important;
    margin-top: -5px;
}

.map-navbar-inner .logo-img{
    height: 42px;
    padding-top: 10px;
    padding-bottom: 5px;
    padding-right: 15px;
}

.map-welcome-user{
    position: absolute;
    top: 0;
    right: 20px;
    padding-top: 20px;
    padding-right: 180px;
}

.map-welcome-user .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    margin-top: 0px;
    margin-bottom: 5px;
    text-decoration: none !important;
}

.logout-btn{
    background-color: rgba(2,127,128);
    color: #ffffff;
    font-family: Trebuchet MS;
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    padding: 14px 15px;
    border: 0px solid #000;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 6px;
   }

.logout-btn:hover{
    background-color: rgb(3, 93, 93);
   }



