/*Tyles Button*/

.map-layer-tyles{
    position: absolute;
    bottom: 0;
    left: 100px;
    padding: 10px;

}

.map-layer-options-tyles .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none !important;
}

.map-layer-tyles-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 10px;
    margin-bottom: 15px;
    width: 42px;
    height: 35px;
    justify-content: center;
}

.map-layer-button-inner .btn-icon-layer{
    padding-top: 4px;
}

.map-layer-option-car .btn-icon-layer{
    padding-top: 4px;
}

.map-layer-option-train .btn-icon-layer{
    padding-top: 4px;
}

.map-layer-option-default-space{
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
}

/*Individual btn for tyles - Order left to right*/
.map-layer-option-default{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-top: 0px;
    margin-bottom: 15px;
    margin-left: 110px;
    width: 80px;
    height: 47px;
    justify-content: center;
}

.map-layer-option-outdoors{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-top: 0px;
    margin-bottom: -49px;
    margin-left: 204px;
    width: 80px;
    height: 47px;
    justify-content: center;
}

.map-layer-option-satellite{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-top: 0px;
    margin-bottom: -47px;
    margin-left: 300px;
    width: 80px;
    height: 47px;
    justify-content: center;
}

.map-layer-option-streets{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-top: 0px;
    margin-bottom: -47px;
    margin-left: 396px;
    width: 80px;
    height: 47px;
    justify-content: center;
}

.map-layer-option-dark{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-top: 0px;
    margin-bottom: -47px;
    margin-left: 492px;
    width: 80px;
    height: 47px;
    justify-content: center;
}

.img-tyle{
    border-radius: 10px 10px 0px 0px;
    height: 30px;
    padding: 0px;
    width: 100%;
    position: inherit;
}

.map-close-navs{
    position: absolute;
    bottom: 0;
    left: 0px;
    padding: 10px;

}

.map-close-navs-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 10px;
    margin-bottom: 15px;
    width: 42px;
    height: 35px;
    justify-content: center;
}

/*Information Button*/
.info-btn{
    position: absolute;
    top: 90px;
    right: 0;
    padding: 10px;

}

.info-btn-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    padding: 2px;
    padding-top: 10px;
    margin-bottom: 15px;
    width: 42px;
    height: 35px;
    justify-content: center;
}