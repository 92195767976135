.modal-pause-play-slider{
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 330px;
    bottom: 0px;
    right: 0px;
    padding: 10px;
}

.modal-pause-play-slider-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 0px;
    width: 307px;
    height: 90px;
}

.modal-pause-play-slider-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
}

.modal-pause-play-slider-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 13px;
}