.modal-potential-routes {
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 550px;
    bottom: 0px;
    right: 0px;
    padding: 10px;
}

.modal-potential-routes-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 527px;
    height: 540px;
}
  
.modal-potential-routes-inner .no-data{
    font-family: 'Inter';
    font-style: normal;
    font-size: small;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    margin-top: 10px;
    color: #6f6f6f;
  }
  
  .modal-potential-routes-inner .no-data-italic{
    font-family: 'Inter';
    font-style: italic;
    font-size: 10px;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    margin-top: 5px;
    color: #353434;
  }

.modal-potential-routes-inner h4{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    text-align: center;
    margin-top: 4px;
    margin-bottom: 3px;
    font-size: small;
    color: #5B5B5B;
  }

  .modal-potential-routes-filter-agency {
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 350px;
    top: 100px;
    left: 0px;
    padding-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.modal-potential-routes-filter-agency-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 350px;
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-potential-routes-filter-agency-inner h1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin-top: 3px;
  margin-bottom: 3px;
  color: #5B5B5B;
}

.column-total{
  width: 525px;
}

/* Create two equal columns that floats next to each other */
.modal-potential-routes-filter-agency-inner .column {
  float: left;
  width: 50%;
  padding: 0px;
}

.modal-potential-routes-filter-agency-inner h4{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-top: 3px;
    margin-bottom: 3px;
    color: #5B5B5B;
}

.modal-potential-routes-buttons {
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 300px;
  bottom: 570px;
  right: 0px;
  padding: 10px;
}

.modal-potential-routes-buttons-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 278px;
  height: 120px;
}

 .modal-potential-routes-buttons-inner h1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin-top: 3px;
  margin-bottom: 3px;
  color: #5B5B5B;
}

.modal-potential-routes-buttons-inner .label{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin-top: 3px;
  margin-bottom: 3px;
  color: #5B5B5B;
}

.fixed-box-modal{
    overflow-y: scroll;
    width: 550px;
    height: 480px;
}


/* The actual timeline (the vertical ruler) */
.timeline {
    position: relative;
    width: 550px;
    height: auto;
    margin: 0 auto;
  }
  
  /* The actual timeline (the vertical ruler) */
  .timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    height: auto;
    background-color: rgba(2,127,128);
    top: 15px;
    bottom: 0;
    left: 40px;
    margin-left: -3px;
  }
  
  /* Container around content */
  .container {
    padding: 10px 30px;
    position: relative;
    background-color: inherit;
    width: 75%;
  }

  .container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
    }
  
  /* The circles on the timeline */
  .container::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background-color: white;
    border: 4px solid rgba(2,127,128);
    top: 15px;
    border-radius: 50%;
    z-index: 1;
  }
  
  /* Place the container to the right */
  .right {
    left: 40px;
  }

  /* Add arrows to the right container (pointing left) */
.right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 20px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent rgb(3 132 133 / 48%) transparent transparent;
  }
  
  /* Add arrows to the left container (pointing right) */
  .left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid rgb(3 132 133 / 48%);
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent rgb(3 132 133 / 48%);
  }

  /* Fix the circle for containers on the right side */
  .right::after {
    left: -16px;
  }
  
  /* The actual content */
  .content {
    padding: 10px 10px;
    background-color: rgb(3 132 133 / 48%);
    position: relative;
    border-radius: 6px;
    height: 50px;
  }

  .content .column-1 {
    float: left;
    width: 70%;
    padding: 0px;
  }

  .content .column-2 {
    float: left;
    width: 30%;
    padding: 0px;
  }

  .column42-eq{
    float: left;
    width: 26%;
    padding: 0px;
  }

  .column41-eq{
    float: left;
    width: 20%;
    padding: 0px;
  }

  .column-1-eq {
    float: left;
    width: 50%;
    padding: 0px;
  }

  .column-2-eq {
    float: right;
    width: 50%;
    padding-top: 0px;
  }

  .column-1-eqr {
    float: left;
    width: 60%;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 10px;
  }

  .column-2-eqr {
    float: right;
    width: 40%;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 10px;
  }

  .column3-1-eq {
    float: left;
    width: 20%;
    padding: 0px;
  }

  .column3-2-eq {
    float: left;
    width: 38%;
    padding: 0px;
  }

  .column3-3-eq {
    float: left;
    width: 38%;
    padding: 0px;
  }

  .deleteElement{
   padding-top: 10%;
  }

  .data_insights{
    margin-top: 60px;
  }

  .data_insights_loader{
    padding-left: 40%;
    padding-top: 30%;
  }

  .data_insights_loader_austria{
    padding-left: 45%;
    padding-top: 20%;
  }


  /* Create two equal columns that floats next to each other */
.data_insights .column-1 {
    float: left;
    width: 60%;
    padding: 0px;
  }

  .data_insights .column-2 {
    float: left;
    width: 40%;
    padding: 0px;
  }

  .data_insights .row {
    margin-top: 20px;
    margin-bottom: 20px;
  }


  /* Create two equal columns that floats next to each other */
  .data_insights p {
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    margin-top: 0px;
    margin-bottom:3px;
  }

  .data_insights h4{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: small;
    color: #5B5B5B;
  }


  .station_insights{
    margin-top: 5px;
  }

  .station_insights .column_70 {
    margin-top: 0px;
    margin-bottom: 5px;
    width: 70%;
    margin-right: 0px;
    float: left;

  }

  .station_insights .column_30 {
    width: 30%;
    margin-bottom: 10px;
    margin-right: 0px;
    float: left;
    margin-top: 38%;
  }

  .station_insights .column_15 {
    width: 15%;
    margin-bottom: 10px;
    margin-right: 0px;
    float: left;
    margin-top: 38%;
  }

  .table_select{
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .table_in_and_out{
    width: 100%;
    padding-bottom: 0px;
  }

  .table_insights{
    width: 100%;
    padding-bottom: 0px;
  }


  .station_insights h4{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 12px;
    color: #5B5B5B;
  }

  .station_insights h5{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 11px;
    color: #5B5B5B;
  }

  .column_30 .title-h4{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: small;
    color: #5B5B5B;
  }

  .incoming{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: rgba(2,127,128) !important;
  }

  .departure{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: rgb(128, 2, 2) !important;
  }

  .blank{
    color: rgb(255, 255, 255) !important;
  }

  .tr-name{
    height: 29px;
  }

  .modal-slider-accessability {
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 250px;
    top: 85px;
    left: 0px;
    padding: 10px;
  }
  
  .modal-slider-accessability-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 225px;
    height: 80px;
  }

    
  .modal-slider-accessability-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
}

.modal-slider-accessability-inner .label{
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-decoration: none !important;
}

.modal-slider-accessability-inner  .insight-analyzer-modal-slider-inner{
  margin-left: 20px;
}


/*Modal change between weekdays and weekends*/
.modal-weekdays-weekends-potential{
  position: absolute;
  bottom: 745px;
  padding-right: 15px;
  right:0px;
}

.modal-weekdays-weekends-potential-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 2px;
  padding-top: 3px;
  margin-bottom: 15px;
  width: 220px;
  height: 42px;
  justify-content: center;
}

.modal-weekdays-weekends-potential-inner .label{
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin-top: 0px;
  margin-bottom:3px;
}

/* Create two equal columns that floats next to each other */
.modal-weekdays-weekends-potential-inner .column {
  float: left;
  width: 50%;
  padding: 0px;
}

/* Clear floats after the columns */
.modal-weekdays-weekends-potential-inner .row:after {
  content: "";
  display: table;
  clear: both;
}

.modal-full-screen-insigths{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 80vw;
  top: 20%;
  left: 3%;
  padding: 10px;
}

.modal-full-screen-insigths-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 90vw;
  height: 500px;
}

.modal-full-screen-insigths-inner .exit-btn{
  margin-left: 90%;
}

.modal-full-screen-insigths-inner h1{
  font-family: 'Inter';
  font-style: normal;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  margin-top: 14px;
  color: #6f6f6f;
}

.modal-full-screen-insigths-inner h2{
  font-family: 'Inter';
  font-style: italic;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  margin-top: 5px;
  color: #353434;
}

.modal-full-screen-insigths-inner .table_select-before{
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
}

.modal-full-screen-insigths-inner .column_fs_15 {
  width: 15%;
  margin-bottom: 10px;
  margin-right: 0px;
  float: left;
  margin-top: 140px;
}


.station_insights_fs{
  margin-top: 20px;
}

.station_insights_fs .column_70 {
  margin-top: 0px;
  margin-bottom: 5px;
  width: 70%;
  margin-right: 0px;
  float: left;

}

.station_insights_fs .column_30 {
  width: 30%;
  margin-bottom: 10px;
  margin-right: 0px;
  float: left;
  margin-top: 38%;
}

.station_insights_fs h4{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 12px;
  color: #5B5B5B;
}

.station_insights_fs h5{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 11px;
  color: #5B5B5B;
}

.search-table{table-layout: fixed; margin:40px auto 0px auto; border-collapse:collapse;  min-height: 90px;}
.search-table td{padding: 0px 10px; height:7px;}

.search-table-outter { overflow-x: scroll;}
.search-table th, .search-table td { min-width: 80px;}

/*Potential of Corridors*/
.modal-potential-corridors-buttons {
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 300px;
  top: 270px;
  left: 0px;
  padding: 10px;
}

.modal-potential-corridors-buttons-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 278px;
  height: 165px;
}

 .modal-potential-corridors-buttons-inner h1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin-top: 3px;
  margin-bottom: 3px;
  color: #5B5B5B;
}

.modal-potential-corridors-buttons-inner .label{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-top: 3px;
  margin-bottom: 3px;
  color: #5B5B5B;
}

.modal-potential-corridors-insights {
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 300px;
  top: 470px;
  left: 0px;
  padding: 10px;
}

.modal-potential-corridors-insights-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 278px;
  height: 40px;
}

 .modal-potential-corridors-insights-inner h1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin-top: 3px;
  margin-bottom: 2px;
  color: #5B5B5B;
}

.modal-potential-corridors-insights-inner .label{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-top: 3px;
  margin-bottom: 3px;
  color: #5B5B5B;
}