.modal-tile-comparison{
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 90px;
    left: 0;
    padding: 10px;
    padding-bottom: 20px;
}

.modal-tile-comparison-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 30px;
    width: 450px;
    height: auto;
}

.modal-tile-comparison-inner .slider-definition{
    margin-left: 5%;
}

.modal-tile-comparison-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
}

.modal-tile-comparison-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.modal-tile-comparison-results{
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 210px;
    right: 0;
    padding: 10px;
}

.modal-tile-comparison-results-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 450px;
    height: 220px;
}

.modal-tile-comparison-results-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
}

.modal-tile-comparison-results-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.modal-tile-comparison-categories{
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 90px;
    right: 0;
    padding: 10px;
}

.modal-tile-comparison-categories-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 450px;
    height: 90px;
}


.modal-tile-comparison-categories-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
}

.modal-tile-comparison-categories-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #E32A8D;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #E32A8D;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .switch-change-div{
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 10px;
    left: 280px;
    padding: 10px;
}

.switch-change-div-inner{
    padding: 10px;
    margin-bottom: 20px;
    width: 200px;
    height: 200px;
}

.switch-change-div-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none !important;
}

.tile-comparison-filter-analyzer{
    position: absolute;
    top: 90px;
    left: 480px;
    padding: 10px;

}

.tile-comparison-filter-analyzer-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 10px;
    margin-bottom: 15px;
    width: 42px;
    height: 35px;
    justify-content: center;
}

.modal-tile-comparision-additional-filters{
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 130px;
    left: 480px;
    padding: 10px;
    padding-top: 20px;
}

.modal-tile-comparision-additional-filters-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 450px;
    height: 230px;
}

.modal-tile-comparision-additional-filters-inner h1{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 13px;
  }

  .modal-tile-comparision-additional-filters-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

.modal-tile-comparision-additional-filters-inner .form-check-label {
    color: #3d3d3d;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
}

.modal-tile-comparision-additional-filters-inner .rc-slider {
    width: 95%;
    margin-left: 5%;
}

.modal-tile-comparision-additional-filters-inner .row-input{
    height: 9px;
  }

  /*Settings for Tile Employer 2D*/

  .tile-employer-2d-filter{
    position: absolute;
    left: 0px;
    top: 460px;
    padding: 10px;

}

.tile-employer-2d-filter-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 10px;
    margin-bottom: 15px;
    width: 42px;
    height: 35px;
    justify-content: center;
}

.kanton-employer-div{
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 10px;
    left: 0px;
    padding: 10px;
    width: 250px;
}

.tile-category-div{
    font-family:'Inter', sans-serif;
    position: absolute;
    top: 10px;
    left: 260px;
    padding: 10px;
    width: 300px;
}