.main-div-menu {
    background: url('../../assets/img/background-menu.png');
    background-size: 100% 100%;
}

.main-div-menu-inner {
    height: 100vh;
    background: rgba(255, 255, 255, 0.14);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.main-div-menu-inner .row-1 {
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
    height: 200px;
    padding-top: 6%;
}

.main-div-menu-inner .row {
    justify-content: center;
    padding-left: 7%;
    padding-right: 7%;
    height: 160px;
}

.main-div-menu-inner .column-20 {
    float: left;
    width: 24%;
    padding: 0%;
    margin-top: 0px;
}

.main-div-menu-inner .column-30l {
    float: left;
    width: 20%;
    padding: 0%;
    padding-left: 8%;
    margin-top: 0px;
}

.main-div-menu-inner .column-30c {
    float: left;
    width: 20%;
    padding-left: 7%;
    margin-top: 0px;
}

.main-div-menu-inner .column-30r {
    float: right;
    width: 20%;
    padding: 0%;
    padding-right: 19%;
    margin-top: 0px;
}

.main-div-menu-inner .item-menu {
    font-family: 'Inter', sans-serif;
    width: 300px;
    padding: 10px;
    padding-left: 15%;
    padding-bottom: 5px;
}

.main-div-menu-inner .centered-item-menu {
    font-family: 'Inter', sans-serif;
    width: 300px;
    padding: 10px;
    padding-bottom: 5px;
    margin: auto;
}

.main-div-menu-inner .item-menu-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 12px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    width: 280px;
    height: 120px;
}

.main-div-menu .column-5{
    
    width: 5%;
    padding: 0px;
  }

.item-menu-inner .label {
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none !important;
}

.item-menu-inner .title {
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none !important;
}

.title-welcome {
    color: #2e2e2e;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 0px;
    text-decoration: none !important;
}

.image-main-menu {
    padding: 10px;
    margin-top: -5px;
}

.image-main-menu .logo-img {
    height: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
}

.powered-by-main-menu {
    font-size: 14px;
    color: black;
    font-family: TheSaB5, 'Trebuchet MS', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    margin: 1px;
    margin-top: -20px;
}

.image-swisscom-main-menu {
    height: 22px;
    padding-top: 0px;
}