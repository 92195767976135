
.main-div-bg {
	background: #FEFBF5;
	background-size: 400% 400%;
}

.main-div-bg-inner{
    height: 100vh;
}

.main-div-bg-inner .sublabel{
    font-size: 20px;
    color: #4b4b4b;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    margin: 15px;
}

.main-div-bg-inner .marketing-powered-by{
    font-size: 20px;
    color: black;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
}

.image-sec-ov42{
    height: 30px;
    padding-top: 4%;
    
}

.btn-marketing-main{
    background-color: #FF3172;
    color: #ffffff;
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    padding: 14px 15px;
    border: 0px solid #000;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20%;
   }

.btn-marketing-main:hover{
    background-color: rgb(187, 42, 88);
   }

.column-eq{
    float: center;
    width: 100%;
    padding: 0px;
    margin: 0px;
    margin-top: 8px;
}

.image-logo-marketing{
    height: 65%;
    padding-top: 5%;
}

/*Marketing Target Group*/
.modal-variables-target-group{
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 90%;
    top: 5%;
    right: 0%;
    padding-top: 8%;
}

.modal-variables-target-group-inner{
    background-color: #FEFBF5;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 87%;
    height: 90%;
}

.modal-variables-target-group-inner .modal-title{
    color: #3e3e3e;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
}

.modal-variables-target-group-inner .label-select-target-group{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    margin-top: 0px;
    margin-bottom: 5px;
}

.modal-variables-target-group-inner .column-1 {
    float: left;
    width: 100%;
    text-align: center;
    margin-top: 8px;
  }

.modal-variables-target-group-inner .column-2 {
    float: left;
    width: 50%;
    text-align: center;
    margin-top: 8px;
  }

.modal-variables-target-group-inner .column-3 {
    float: left;
    width: 33.3%;
    text-align: center;
    margin-top: 8px;
  }

.marketing-btn{
    background-color: #FF3172;
    color: #ffffff;
    font-family: Trebuchet MS;
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    padding: 14px 15px;
    border: 0px solid #000;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 6px;
    margin-top: 10px;
   }

.marketing-btn:hover{
    background-color: #b11345;
   }

/* Insights location */

.map-modal-initial-description{
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 330px;
    top: 90px;
    right: 0;
    padding: 10px;
}

.map-modal-initial-description-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 307px;
    height: 280px;
}

.map-modal-initial-description h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
}

.map-modal-initial-description .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 13px;
}

.generate-btn{
    background-color: rgb(2, 115, 128);
    color: #ffffff;
    font-family: Trebuchet MS;
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    padding: 14px 15px;
    border: 0px solid #000;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 6px;
   }

    Button:disabled {
        background-color: rgb(136, 136, 136);
        color: #ffffff;
        font-family: Trebuchet MS;
        font-size: 13px;
        font-weight: 400;
        font-style: normal;
        text-decoration: none;
        padding: 14px 15px;
        border: 0px solid #000;
        border-radius: 10px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 6px;
   }

   Button:disabled:hover {
    background-color: rgb(136, 136, 136);
    color: #ffffff;
    font-family: Trebuchet MS;
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    padding: 14px 15px;
    border: 0px solid #000;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 6px;
}

.generate-btn:hover{
    background-color: rgb(3, 59, 93);
   }

   .excel-btn{
    background-color: rgb(2, 128, 27);
    color: #ffffff;
    font-family: Trebuchet MS;
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    padding: 14px 15px;
    border: 0px solid #000;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 6px;
   }

   .excel-btn:hover{
    background-color: rgb(3, 93, 21);
   }

.reset-btn{
    background-color: rgb(128, 2, 2);
    color: #ffffff;
    font-family: Trebuchet MS;
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    padding: 14px 15px;
    border: 0px solid #000;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 6px;
   }

.reset-btn:hover{
    background-color: rgb(93, 10, 3);
   }

.bg-loader {
    background-color: #f3f3f3;
    width: 100%;
    height: auto;
  }

.loader {
    border: 10px solid #f3f3f3;
    border-top: 10px solid rgb(15, 171, 171);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 

.pos-center {
    position: fixed;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
  }


  .modal-alert{
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 330px;
    top: 40%;
    right: 40%;
    padding: 10px;
}

.modal-alert-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 307px;
    height: 90px;
}

.modal-alert-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
}

.modal-alert-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 13px;
}

.slider-modal{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 330px;
  top: 180px;
  right: 0;
  padding: 10px;
}

.slider-modal-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 307px;
  height: 70px;
}

.slider-modal-inner h1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 2px;
  color: #5B5B5B;
}

.slider-modal-inner .label{
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  margin-top: 2px;
  margin-bottom: 0px;
}

.slider-modal-inner .slider-distance{
  margin-left: 30px;
}

.slider-modal-inner .form-check-label{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #5B5B5B;
}

/*Employer selector*/
.map-layer-slider-select-employer{
  position: absolute;
  top: 90px;
  left: 0px;
  padding: 10px;
}

.map-layer-slider-select-employer-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  margin-bottom: 15px;
  width: 300px;
  height: 80px;
  justify-content: center;
}

.map-layer-slider-select-employer-inner h1{
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: -10px;
}

.slider-2-modal{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 330px;
  top: 280px;
  right: 0;
  padding: 10px;
}

.slider-2-modal-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 307px;
  height: 280px;
}

.slider-2-modal-inner h1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 2px;
  color: #5B5B5B;
}

.slider-2-modal-inner .label{
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  margin-top: 2px;
  margin-bottom: 0px;
}

.slider-2-modal-inner .label-2{
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  margin-top: 2px;
  margin-bottom: 5px;
}

.slider-2-modal-inner .slider-distance{
  margin-left: 30px;
}

.slider-2-modal-inner .form-check-label{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #5B5B5B;
}

.column-2-30{
  float: right;
  width: 30%;
  padding: 0px;
}

.column-1-60{
  float: left;
  width: 60%;
  padding: 0px;
  margin-left: 30px;
}

.map-btn-bus-potential-inner .label-2{
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 0px;
}

.row-parameters{
  margin-bottom: 10px;
  margin-left: 10px;
}

/** brain loader **/
.brain-loader{
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
/* 	border:2px solid red; */
	height:150px;
	width:150px;
}
/* path length */
/* 670.0289916992188 */

.brain-loader path {
  stroke-dasharray: 670;
  stroke-dashoffset: 670;
  animation: dash 4s linear infinite;
}

@keyframes dash {
	0%{opacity:1;}
  80% {
    stroke-dashoffset: 0;
		opacity:1;
  }
	90%{
		stroke-dashoffset: 0;
		opacity:1
	}
	100%{
		stroke-dashoffset: 0;
		opacity:0;
	}
}

.circle-loading-brain{
  width: 250px;
  height: 250px;
  background: #f3d8ed;
  border-radius: 50%;
}

.pos-center-brain-loader {
  position: fixed;
  top: calc(50% - 90px);
  left: calc(50% - 150px);
}

.map-top-origins{
  font-family:'Inter', sans-serif;
  position: absolute;
  width: 410px;
  top: 90px;
  left: 0;
  padding: 10px;
}

.map-top-origins-inner{
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  width: 420px;
  height: 240px;
}

.map-top-origins-inner h1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 10px;
  color: #5B5B5B;
}

.map-top-origins-inner h3{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 2px;
  color: #232222;
}

.map-top-origins-inner h4{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 2px;
  color: #5B5B5B;
}

.table-tittle{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}

.table-data{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.map-graph-value{
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 520px;
    top: 90px;
    left: 0;
    padding: 10px;
  }
  
  .map-graph-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 540px;
    height: 630px;
  }

  .map-top-origins-inner tr:hover{
    background-color: #defffc;
    cursor: pointer;
  }

  .map-top-origins-inner .title:hover{
    background-color: #808080;
    cursor: default;
  }

  .map-top-origins-inner .title{
    background-color: #808080;
    cursor: default;
  }

  .table-title-marketing{
    color: #FEFBF5;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}


  .map-top-origins-inner table, .map-top-origins-inner th, .map-top-origins-inner td {
    border: 1px solid;
    border-collapse: collapse;
    border-color: #5B5B5B;
  }

  .label-title {
  color: #5B5B5B;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.table-center-marketing {
  margin-left: auto;
  margin-right: auto;
}