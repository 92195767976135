/* Dashboard 5-in-5 */
.dashboard-5-in-5 {
	background: rgb(252, 252, 252);
	background-size: 400%;
}

.dashboard-5-in-5-inner{
}

.dashboard-5-in-5-inner .dashboard-table-frame{
    padding: 3%;
    padding-top: 100px;
    height: 60vh;
}

.dashboard-table-frame h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 18px;
    text-align: left;
    margin-top: 4%;
    color: #5B5B5B;
    margin-bottom: 20px;
}

.dashboard-table-frame .label-text{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 0px;
  }

.dashboard-table-frame h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 18px;
    text-align: left;
    margin-top: 10px;
    color: #5B5B5B;
    margin-bottom: 25px;
}

.dashboard-table-frame h3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-align: left;
    margin-top: 0px;
    color: #5B5B5B;
    margin-bottom: 25px;
}

.dashboard-table-frame br{
    margin-top: 0px;
    margin-bottom: 0px;
}

.dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
  }

/*Icon of settings*/

.settings-dashboard-btn-inner{
    background-color: #ffffff;
    border: rgba(0, 0, 0, 0.25);
    border-style: solid;
    border-radius: 10px;
    padding: 2px;
    padding-top: 7px;
    margin-bottom: 0px;
    width: 25px;
    height: 20px;
    justify-content: center;
}

.slider-center .rc-slider{
    width: 90%;
    margin-left: 10%;
    }

    .panel-detail{
        font-family:'Inter', sans-serif;
        position: absolute;
        top: 90px;
        right: 0px;
        padding: 0px;
      }
      
      .panel-detail-inner{
        background-color: #ffffff;
        -webkit-box-shadow: -8px 0 10px -6px #606060;
	-moz-box-shadow: -8px 0 10px -6px #606060;
	box-shadow: -8px 0 10px -6px #606060;
        padding: 0px;
        margin-bottom: 0px;
        width: 50vw;
        height: 90vh;
        z-index: 3;
        position: relative;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 0px;
        padding-top: 30px;
      }


      .panel-detail-inner h1{
        font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 0px;
    color: #5B5B5B;
      }
 
      .panel-detail-inner h2{
        font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    margin: 0px;
    text-align: left;
    color: #5B5B5B;
      }

      .panel-detail-inner h3{
        font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    margin: 0px;
    text-align: left;
    color: #5B5B5B;
      }

      .panel-detail #map-container {
        position: relative;
        height: 260px;
        width: auto;
      }
      
      .panel-detail #map {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }

      .panel-detail .map {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        }