@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

/*Trip Analyzer*/

.map-card-trip-analyzer {
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 280px;
    bottom: 0;
    right: 0;
    padding: 10px;
}

.division-btn-cars{
    border: 0px;
    background-color: #c9c9c9;
    height: 30px;
}

.division-btn-cars:hover{
    background-color: #5B5B5B;
    color: #ffffff;
    border: 0px;
}

.division-btn-cars:focus, .division-btn-cars:active{
    background-color:#85182a;
    color: #ffffff;
}
     
.map-card-trip-analyzer-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 257px;
    height: 230px;
}

.map-card-trip-analyzer-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
}

.map-card-trip-analyzer-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.locations__control {
    height: 20px;
    min-height: 20px;
}

/*Filter Analyzer*/

.map-card-filter-analyzer {
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 280px;
    bottom: 260px;
    right: 0;
    padding: 10px;
}
     
.map-card-filter-analyzer-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 257px;
    height: 150px;
}

.map-card-filter-analyzer-inner .label-selected-value{
    font-size: 10px;
    text-align: center;
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
}

.map-card-filter-analyzer-inner .filter-analyzer-slider-inner{
    margin-left: 20px;
}

.map-card-filter-analyzer-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
}

.map-card-filter-analyzer-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

/*Navegation Bar - Slider Time*/

.map-time-slider{
    position: absolute;
    top: 10px;
    right: 200px;
    padding: 10px;
    margin-top: 5px;
    width: 500px;
    height: 50px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.map-time-slider-inner{
    margin-left: 40px;
}

.map-time-slider h1{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 9px;
}

.map-time-slider .slider-additional-info{
    background-color: #5B5B5B;
    width: 450px;
}

/*Slider Change color*/
  .rc-slider{
    width: 90%;
    }
  .rc-slider-track {
    background-color: #bd1f36;
  }
  .rc-slider-handle {
    border: solid 2px #bd1f36;
  }
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: #bd1f36;
    box-shadow: 0 0 0 5px #bd1f36;
  }
  .rc-slider-handle:focus-visible {
    border-color: #bd1f36;
    box-shadow: 0 0 0 3px #bd1f36;
  }
  .rc-slider-handle-click-focused:focus {
    border-color: #bd1f36;
    box-shadow: unset;
  }
  .rc-slider-handle:hover {
    border-color: #bd1f36;
  }
  .rc-slider-handle:active {
    border-color: #bd1f36;
    box-shadow: 0 0 5px #bd1f36;
  }
  .rc-slider-dot-active {
    border-color: #bd1f36;
  }
  
/*Gradient convection*/

.map-layer-convention{
    position: absolute;
    bottom: 0;
    left: 50px;
    padding: 10px;

}

.map-layer-convention-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2px;
    padding-top: 5px;
    margin-bottom: 15px;
    width: 250px;
    height: 40px;
    justify-content: center;
}

.map-layer-convention-inner .label-convention{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
}

/* Create three columns that floats next to each other */
.map-layer-convention-inner .column-1 {
    float: left;
    width: 20%;
    text-align: center;
    margin-top: 8px;
  }
.map-layer-convention-inner .column-2 {
    float: left;
    width: 50%;
    text-align: center;
    margin-top: 14px;
  }
.map-layer-convention-inner .column-3 {
    float: left;
    width: 30%;
    text-align: center;
    margin-top: 8px;
  }

.map-layer-convention-inner .convention-linear-red{
    background: red;
    background: linear-gradient(0.25turn, #e01e37, #641220);
    height: 10px;
    width: 100%;
    bottom: 0;
}

.row{
    justify-content: center;
}

.row-padding{
    justify-content: center;
    margin-bottom: 10px;
}