@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

/*Trip Analyzer*/

.map-card-distance-analyzer {
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 280px;
    bottom: 0;
    right: 0;
    padding: 10px;
}

.division-btn-cars{
    border: 0px;
    background-color: #c9c9c9;
    height: 30px;
}

.division-btn-cars:hover{
    background-color: #5B5B5B;
    color: #ffffff;
    border: 0px;
}

.division-btn-cars:focus, .division-btn-cars:active{
    background-color:#85182a;
    color: #ffffff;
}
     
.map-card-distance-analyzer-inner {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 257px;
    height: 120px;
}

.map-card-distance-analyzer-inner h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px;
    color: #5B5B5B;
}

.map-card-distance-analyzer-inner .label{
    color: #5B5B5B;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.locations__control {
    height: 20px;
    min-height: 20px;
}

/*Slider Change color*/

  .rc-slider-track {
    background-color: #bd1f36;
  }
  .rc-slider-handle {
    border: solid 2px #bd1f36;
  }
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: #bd1f36;
    box-shadow: 0 0 0 5px #bd1f36;
  }
  .rc-slider-handle:focus-visible {
    border-color: #bd1f36;
    box-shadow: 0 0 0 3px #bd1f36;
  }
  .rc-slider-handle-click-focused:focus {
    border-color: #bd1f36;
    box-shadow: unset;
  }
  .rc-slider-handle:hover {
    border-color: #bd1f36;
  }
  .rc-slider-handle:active {
    border-color: #bd1f36;
    box-shadow: 0 0 5px #bd1f36;
  }

  .rc-slider-dot-active {
    border-color: #bd1f36;
  }