
.image-logo{
    height: 25%;
    padding-top: 10%;
}

.image-logo-access-denied{
  height: 25%;
  padding-top: 3%;
}

.image-swisscom{
  height: 30px;
  padding-top: 8%;
  
}

.main-div-inner{
    height: 100vh;
}

.main-div {
	background: linear-gradient(-45deg, #52d3c4, #317989, #001f33, #121626);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.motto{
    font-size: 40px;
    color: white;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
}

.swisscom-font{
  font-family: TheSaB5,'Trebuchet MS',Arial,Helvetica,sans-serif;
  font-style: normal;
  font-weight: 800;
}

.powered-by{
  font-size: 20px;
  color: white;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
}

.powered-by-access-denied{
  font-size: 20px;
  color: white;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
}

.powered-by-navbar{
  font-size: 10px;
  color: black;
  font-family: TheSaB5,'Trebuchet MS',Arial,Helvetica,sans-serif;
  font-style: normal;
  font-weight: 600;
  margin: 1px;
  margin-top: -12px;
}

.image-swisscom-navbar{
  height: 16px;
  padding-top: 0px;
}

.btn-login{
    background-color: #ffffff;
    color: #000000;
    font-family: Trebuchet MS;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    padding: 14px 15px;
    border: 0px solid #000;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 10%;
   }

.btn-login:hover{
    background-color: #f8f8f8;
   }

   .btn-main-page{
    background-color: #e32a8d;
    color: #ffffff;
    font-family: Trebuchet MS;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    padding: 14px 15px;
    border: 0px solid #000;
    border-radius: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 200px;
   }

   .btn-main-page:hover{
    background-color: #901958;
   }

   .loader {
    border: 10px solid #f3f3f3;
    border-top: 10px solid rgb(15, 171, 171);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 

  .pos-center {
    position: fixed;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
  }

  .modal-access-denied{
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 330px;
    top: 12%;
    left: 25%;
    padding: 10px;
}

  .modal-access-denied-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 800px;
    height: 600px;
}

.access-denied-text{
  font-size: 18px;
  color: rgb(0, 0, 0);
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  text-align: justify;
  padding-left: 100px;
  padding-right: 100px;
}