.header-co2-insights {
    background-color: white;
}

.header-co2-insights-inner {
    height: 90vh;
}

.column-40{
    float: left;
    width: 40%;
    padding: 0px;
  }

  .column-60{
    float: right;
    width: 60%;
    padding: 0px;
  }

  .header-co2-insights-inner  .label{
    color: #2f2f2f;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none !important;
  }


  .header-co2-insights-inner  .label-small{
    color: #2f2f2f;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none !important;
  }

  .submit-btn{
    background-color: rgb(157, 3, 98);
    color: #ffffff;
    font-family: Trebuchet MS;
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    padding: 15px 15px;
    border: 0px solid #000;
    border-radius: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 150px;
    margin-top: 20px;
   }

.submit-btn:hover{
    background-color: rgb(93, 3, 66);
   }

   .initial-section-resume{
    padding: 5%;
    padding-top: 2%;
    height: 50vh;
    background-color: #f6f5f3;
   }


   .section-mobility-patterns{
    padding: 2%;
    padding-left: 5%;
    padding-right: 5%;
    height: 50vh;
    background-color: #f6f5f3;
   }
  

   .section-divider{
    padding: 2%;
    padding-left: 5%;
    padding-right: 5%;
   }

   .initial-section-resume h1{
    color: #2f2f2f;
    font-family: "IBM Plex Sans", sans-serif;
    font-style: normal;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none !important;
   }

   .initial-section-resume h2{
    color: #2f2f2f;
    font-family: 'Inter';
    font-style: normal;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none !important;
   }

   .initial-section-resume h3{
    color: #2f2f2f;
    font-family: 'Inter';
    font-style: normal;
    margin-top: 0px;
    font-size: 20px;
    padding-top: 5px;
    margin-bottom: 0px;
    text-decoration: none !important;
   }

   .initial-section-resume .label-small{
    color: #2f2f2f;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none !important;
  }

  .section-mobility-patterns h1{
    color: #2f2f2f;
    font-family: 'Inter';
    font-style: normal;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none !important;
   }

  .section-mobility-patterns h2{
    color: #2f2f2f;
    font-family: 'Inter';
    font-style: normal;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none !important;
   }

   .section-mobility-patterns h3{
    color: #2f2f2f;
    font-family: 'Inter';
    font-style: normal;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none !important;
   }

   .section-mobility-patterns p{
    color: #2f2f2f;
    font-family: 'Inter';
    font-style: normal;
    text-align: justify;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none !important;
   }

   .sub-sections-btn{
    background-color: rgb(157, 3, 57);
    color: #ffffff;
    font-family: Trebuchet MS;
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    padding: 15px 15px;
    border: 0px solid #000;
    border-radius: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 150px;
    margin-top: 20px;
   }

.sub-sections-btn:hover{
    background-color: rgb(93, 3, 30);
    color: #ffffff;
    font-family: Trebuchet MS;
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    padding: 15px 15px;
    border: 0px solid #000;
    border-radius: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 150px;
    margin-top: 20px;
   }

   .sub-sections-btn:disabled{
    background-color: rgb(93, 3, 30);
    color: #ffffff;
    font-family: Trebuchet MS;
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    padding: 15px 15px;
    border: 0px solid #000;
    border-radius: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 150px;
    margin-top: 20px;
   }

   .sub-sections-btn:disabled:hover{
    background-color: rgb(93, 3, 30);
    color: #ffffff;
    font-family: Trebuchet MS;
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    padding: 15px 15px;
    border: 0px solid #000;
    border-radius: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 150px;
    margin-top: 20px;
   }

   .table-5in5-point{
    font-family:'Inter', sans-serif;
    position: absolute;
    width: 330px;
    top: 90px;
    right: 0;
    padding: 10px;
}

.table-5in5-point-inner{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 307px;
    height: 150px;
}

.img-measure{
    width: 70%;
}

.text-only-btn{
  color: #871f4e;
  background-color: #F6F5F3;  
  font-family: Trebuchet MS;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  padding: 15px 15px;
  border: 0px solid #000;
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
 }

.text-only-btn:hover{
  color: #53122f;
  background-color: #F6F5F3;  
  font-family: Trebuchet MS;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  padding: 15px 15px;
  border: 0px solid #000;
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
 }

 .text-only-btn:disabled{
  background-color: #53122f;
  color: #F6F5F3;
  font-family: Trebuchet MS;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  padding: 15px 15px;
  border: 0px solid #000;
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
 }

 .text-only-btn:disabled:hover{
  background-color: #53122f;
  color: #F6F5F3;
  font-family: Trebuchet MS;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  padding: 15px 15px;
  border: 0px solid #000;
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
 }


 .footer-section{
  padding: 2%;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #52171d;
 }

 .square {
  height: 16px;
  width: 16px;
}

 .submit-5in5-btn{
  background-color: #721a36;
  color: #ffffff;
  font-family: Trebuchet MS;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  padding: 15px 15px;
  border: 0px solid #000;
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 150px;
  margin-top: 20px;
 }

.submit-5in5-btn:hover{
  background-color: #52171D;
 }

 .footer-logo-img{
  height: 70px;
    padding-top: 10px;
    padding-bottom: 5px;
    padding-right: 15px;
 }